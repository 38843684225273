import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import { withTranslation } from "react-i18next";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';

class AlertasTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: '',
        sinDatos: false,
        agendas: '',
        contactos: ''


    };
    componentDidMount() {

    }

    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'Agenda'} />
        }

        return (
            <div className="container h-100 float-left" >
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">Alertas</span>
                </div>
                <div className="agendas-tabs">
                    <div className="list-component-asociado">
                        <div className="tarifa-nombre">
                            <span className="tarifa">Nombre:  </span>
                            <span className="tarifa-nombre-des">Cambio de estado ofertas</span>
                        </div>
                        <div className="tarifa-telefono">
                            <div className="icon-right" >
                                <FontAwesomeIcon icon="chevron-right" className="icon" />
                            </div>
                        </div>
                        <div className="tarifa-nombre">
                            <span className="tarifa">Componente:  </span>
                            <span className="tarifa-nombre-des">Ofertas</span>
                        </div>
                        <div className="tarifa-telefono">
                            <span className="tarifa">Mostrar: </span>
                            <span className="tarifa-nombre-des">Siempre</span>
                        </div>
                    </div>
                    <hr />
                    <div className="list-component-asociado">
                        <div className="tarifa-nombre">
                            <span className="tarifa">Nombre:  </span>
                            <span className="tarifa-nombre-des">Citas pendientes</span>
                        </div>
                        <div className="tarifa-telefono">
                            <div className="icon-right" >
                                <FontAwesomeIcon icon="chevron-right" className="icon" />
                            </div>
                        </div>
                        <div className="tarifa-nombre">
                            <span className="tarifa">Componente:  </span>
                            <span className="tarifa-nombre-des">Citas</span>
                        </div>
                        <div className="tarifa-telefono">
                            <span className="tarifa">Mostrar: </span>
                            <span className="tarifa-nombre-des">Siempre</span>
                        </div>
                    </div>

                </div>
                <Link
                    to={{
                        pathname: `/Alerta`,
                    }}
                >
                    <button className='btn-aceptar'>Nueva Alerta</button>
                </Link>
            </div >
        );
    };

}
const Alertas = withTranslation('common')(AlertasTranslated)
export default Alertas;