import React from 'react';
import api from '../../components/servicios/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { IonItem, IonLabel } from '@ionic/react';
import { withTranslation } from "react-i18next";
import Util from '../../components/servicios/Util';
import PageError from '../../components/PantallaCarga/PageError';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import i18next from "i18next";
import Alertas from '../../components/servicios/Alertas';
import PiePagina from '../../components/Navbar/PiePagina';
let respuesta = '';



class CambiarContraseniaIndexTranslated extends React.Component {
    constructor(props) {
        super(props);

        this.passwordOneRef = React.createRef();
        this.passwordtwoRef = React.createRef();
        this.iconRevealPassword = React.createRef();

        this.state = {
            form: {
                contraseña: '',
                contraseñaRepeat: ''
            },
            isRevealPassword: false,
            valuePasswordOne: '',
            error: false,
            alert: false,
            alertOk: false,
            alertKo: false
        };
    }
    componentDidMount() {
        const idioma = JSON.parse(localStorage.getItem("idioma"));
        i18next.changeLanguage(idioma);
        this.forceUpdate();
    }

    handleChange = async e => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value
            }
        });
        console.log(this.state.form)
    }


    cerrar() {
        window.location = '/Login';
    }
    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }
    abrirAlert = () => {
        this.setState({ alert: !this.state.alert });
    }

    inicioSesion = async () => {
        const cliente = JSON.parse(localStorage.getItem('user'));
        const idioma = JSON.parse(localStorage.getItem("idioma"));

        if ((this.state.form.contraseña) !== (this.state.form.contraseñaRepeat) || (this.state.form.contraseña) === '' || (this.state.form.contraseñaRepeat) === '') {
            this.abrirAlert();
        } else if (Util.verificarpass(this.state.form.contraseña, idioma) !== 'ok') {
            Alertas.verificarPass(Util.verificarpass(this.state.form.contraseña, idioma), idioma)
        }

        else {
            await api.Clientes.modificarContraseña(cliente.codigo, this.state.form.contraseña)
                .then(({ data }) => respuesta = data);
            //console.log(respuesta);
            if (respuesta === 'OK') {
                this.abrirAlertOk();
            } else {
                this.abrirAlertKo();
            }
        };

    }
    tooglePassword = e => {
        this.setState({ isRevealPassword: !this.state.isRevealPassword });
    }


    render() {
        const { t } = this.props;
        const { isRevealPassword, valuePasswordOne } = this.state;
        if (this.state.error) {
            return <PageError />;
        }
        return (
            <div className="container h-100">
                <div className="contraseniaMargin"></div>
                <div className="titulo-pagina">
                    <span>{t('CambioPass.titulo',)}</span>
                </div>
                <div className="info-contraseña">
                    <span className="info-contraseña">{t('CambioPass.info',)}</span>
                </div>
                <form onSubmit={this.props.onSubmit} className="formulario-contraseña">
                    <div className="info-contraseña">
                        <IonLabel>{t('CambioPass.infoIntroduzca',)} </IonLabel>
                    </div>
                    <IonLabel className="label" >{t('CambioPass.nueva',)}</IonLabel>
                    <IonItem className="form-in">
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type={isRevealPassword ? "text" : "password"}
                            name="contraseña"
                            placeholder={t('CambioPass.contrasenia',)}
                        />
                        <span onClick={this.tooglePassword} ref={this.iconRevealPassword}>
                            <span>
                                {isRevealPassword ?
                                    <FontAwesomeIcon icon="eye" className="icon-eye" /> :
                                    <FontAwesomeIcon icon="eye-slash" className="icon-eye" />
                                }
                            </span>
                        </span>
                    </IonItem>
                    <IonLabel className="label" htmlFor="contraseña">{t('CambioPass.repita',)}</IonLabel>
                    <IonItem className="form-in">
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type={isRevealPassword ? "text" : "password"}
                            name="contraseñaRepeat"
                            placeholder={t('CambioPass.contrasenia',)}
                        />
                        <span onClick={this.tooglePassword} ref={this.iconRevealPassword}>
                            <span>
                                {isRevealPassword ?
                                    <FontAwesomeIcon icon="eye" className="icon-eye" /> :
                                    <FontAwesomeIcon icon="eye-slash" className="icon-eye" />
                                }
                            </span>
                        </span>
                    </IonItem>
                    <div className="botones-linea">
                        <button type="button" onClick={() => this.inicioSesion()} className="btn-aceptar">
                            <FontAwesomeIcon icon="circle-check" className="icon-btn" /> {t('Button.aceptar',)}
                        </button>
                        <button type="button" onClick={() => this.cerrar()} className="btn-rojo-s">
                            <FontAwesomeIcon icon="circle-xmark" className="icon-red" />{t('Button.cancelar',)}
                        </button>
                    </div>
                    {this.props.error && (
                        <p className="password-danger">{this.props.error.message}</p>
                    )}
                </form>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='error'
                    header={t('CambioPass.noCoincide',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[`${t('Aceptar.acep',)}`]}
                />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('CambioPass.passOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            localStorage.clear();
                            window.location = '/Login';
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('CambioPass.passKo',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            localStorage.clear();
                            window.location = '/Login';
                        }
                    }]}
                />
            </div>
        );
    }

};
const CambiarContraseniaIndex = withTranslation('common')(CambiarContraseniaIndexTranslated)
export default CambiarContraseniaIndex;

