import React from "react";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import FacturasList from "../../components/Facturas/FacturasList";
import api from "../../components/servicios/api";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import { Keyboard } from '@capacitor/keyboard';
import { isPlatform } from '@ionic/react';
import ClientesPotencialesList from "../../components/Clientes/ClientesPotencialesList";

import "../../styles/GlobalPages.css";
let userData = [];

class ClientesPotencialesTranslated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            datos: undefined,
            sinDatos: false,
        }

    };
    componentDidMount() {
        this.fetchData();
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null, datos: undefined });
        userData = '';
        try {
            await api.Clientes.clientesPotenciales().then(
                ({ data }) => (userData = data)
            );
            if (Array.isArray(userData)) {
                this.setState({ loading: false, datos: userData, sinDatos: false });
            } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>listarClientes</b>" == userData) {
                this.setState({ loading: false, sinDatos: true, datos: false });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };

    render() {
        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span>{t('Clientes.titulo',)} Potenciales</span>
                </div>
                <div className={this.state.sinDatos ? 'lista' : 'hidden'}>
                    <span className="no-coment">{t('Facturas.info',)}</span>
                </div>
                <div className={this.state.sinDatos ? 'hidden' : 'lista'}>
                    {this.state.datos && <ClientesPotencialesList clientes={this.state.datos} />}
                </div>
                {this.state.loading && <MiniLoader />}
                <PiePagina />
            </div>
        );
    }
}
const ClientesPotenciales = withTranslation('common')(ClientesPotencialesTranslated)
export default ClientesPotenciales;
