import React, { Component } from 'react';
import "../../styles/GlobalComponents.css";
import { withTranslation } from "react-i18next";

class MiniLoaderTranslated extends Component {
  render() {
    const { t } = this.props;
    return (
      <div>
        <div className="lds-grid">
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
          <div />
        </div>
        <div style={{
          color: this.props.color
        }}> {t('PagoError.procesando')}</div>
      </div>

    );
  }
}

const MiniLoader = withTranslation('common')(MiniLoaderTranslated)
export default MiniLoader;
