import React from "react";
import "../../styles/GlobalComponents.css";
import Moment from 'react-moment';
import { withTranslation } from "react-i18next";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import api from '../../components/servicios/api';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import { Progress } from 'reactstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Util from "../../components/servicios/Util";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import DescuentoLinea from "../../components/Descuentos/DescuentoLinea";
let userConsumo;
let userFac;

class DescuentoTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
        factura: '',
        pendiente: '',
        modal: false,
    };

    componentDidMount() {
        this.fetchData(this.props.descuento.id);
    }

    abrirModal = () => {

        this.setState({ modal: !this.state.modal });

    }

    fetchData = async (idDesc) => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.consultarDescuentosDetalle(cliente.usuario, idDesc).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                await api.Clientes.obtenerNumFactura(this.props.descuento.factura).then(
                    ({ data }) => (userFac = data)
                ).catch(function (err) {
                    console.log(err);
                });
                console.log('first')
                let pen = this.props.descuento.total_descuento - this.props.descuento.total_pendiente;

                this.setState({ loading: false, datos: userConsumo, factura: userFac, pendiente: Util.aFloat(pen) + '€' });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };



    render() {

        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'sinDDi'} />
        }
        return (

            <div className="container-component">
                <div className="dettalles-contrato" onClick={this.abrirModal}>
                    <div className="descuento-contenedor">
                        <div className="contenedor-icono-descuento">
                            <FontAwesomeIcon icon="percent" className="icon-btn" />
                        </div>

                        <div className="descuento">
                            <span className="">{this.state.factura}</span>
                            <br></br>
                            <span className=""><Moment format="DD/MM/YYYY ">{this.props.descuento.fecha_factura}</Moment></span>
                            <br></br>
                            <span className="">{Util.aFloat(this.props.descuento.total_descuento)}€</span>
                        </div>
                    </div>

                    <div className="barraPro">
                        <Progress
                            animated
                            color="info"
                            value={this.props.descuento.total_descuento - this.props.descuento.total_pendiente}
                            barClassName="barra"
                            barAriaValueText={"exto"}
                        >{this.state.pendiente}</Progress>
                    </div>

                </div>
                <div>
                    <Modal isOpen={this.state.modal} >
                        <ModalHeader className="modal-header-puk"
                            style={{
                                background: this.props.color,
                            }}>
                            <span className="titulo-modal-descuentos">Detalle Descuentos</span>
                            <div className="modal-cerrar-tarifa">
                                <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                    <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                                </button>
                            </div>
                        </ModalHeader>
                        <ModalBody className="modal-body-puk">
                            {this.state.datos.map(dato => {
                                return (
                                    <DescuentoLinea datos={dato} />
                                )
                            })}
                        </ModalBody>
                        <ModalFooter className="modal-footer-puk">
                        </ModalFooter>
                    </Modal>


                </div>
            </div >
        );
    }
}
const Descuento = withTranslation('common')(DescuentoTranslated)
export default Descuento;