import React, { useState, useEffect } from 'react';
import { PayPalScriptProvider, PayPalButtons } from "@paypal/react-paypal-js";
import ReactDOM from "react-dom"
import api from "../../components/servicios/api";
import ApiDirect from '../servicios/ApiDirect';


let respuesta;
const PaypalPago = (props) => {
    const [isDisabled, setIsDisabled] = useState(false);

    const token = props.data.token;
    const importe = props.data.cantidad;
    const factura = props.data.numfactura;
    const idCobro = props.data.idCobro;
    const contrato = props.data.contrato;
    const fechainicio = props.data.fechainicio;
    const fechafin = props.data.fechafin;
    const bono = props.data.bono;
    const ddi = props.data.ddi;

    const initialOptions = {
        "client-id": token,
        currency: "EUR",
        intent: "capture"
    };


    const capturaPago = async (resultado) => {
        if (contrato == true) {
            await ApiDirect.Clientes.capturaPagoRecargas(idCobro, resultado, "paypal", fechainicio, fechafin).then(
                ({ data }) => (respuesta = data)
            );
        } else if (bono == true) {
            await ApiDirect.Clientes.capturaPagoBono(idCobro, resultado, "paypal", ddi, factura).then(
                ({ data }) => (respuesta = data)
            );
        } else {
            await ApiDirect.Clientes.capturaPago(idCobro, resultado, "paypal").then(
                ({ data }) => (respuesta = data)
            );
        }
        if (respuesta != "Pago registrado correctamente = 1") {
            return false;
        } else {
            return true;
        }


    }



    return (

        <div>
            <PayPalScriptProvider options={initialOptions}>
                <PayPalButtons style={{ layout: "horizontal", height: 55, color: "white", tagline: false }} disabled={isDisabled} createOrder={(data, actions) => {
                    return actions.order.create({
                        intent: "CAPTURE",
                        purchase_units: [
                            {
                                description: factura,
                                amount: {
                                    currency_code: "EUR",
                                    value: importe,
                                },
                            },
                        ],
                    });
                }} onApprove={async (data, actions) => {
                    setIsDisabled(true);
                    const order = await actions.order.capture();
                    let apellido = order.payer.name.surname;
                    let nombre = order.payer.name.given_name;
                    let resul = nombre.concat('', apellido);
                    const resIsp = await capturaPago(resul);
                    if (resIsp == true) {
                        window.location = '/PagoCorrecto';
                    } else {
                        window.location = '/PagoProblemas';
                    }
                }} onError={(err) => {
                    window.location = '/PagoIncorrecto';

                }} onCancel={(data, actions) => {
                    window.location = '/PagoIncorrecto';

                }}
                />
            </PayPalScriptProvider>
        </div>
    )
};
export default PaypalPago;