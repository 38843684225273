import React from 'react';
import { withTranslation } from "react-i18next";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import info from '../../Imagenes/info.png';

class PagoProblemasTranslated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="error-pago-text">
                    <span> {t('PagoProblemas.disculpa')}</span>
                </div>
                <div className="error-pago">
                    <img className="error-pago-img" src={info} alt="Logotipo" />
                </div>
                <div className="error-pago-info">
                    {t('PagoProblemas.info')}
                </div>
            </div>
        )

    }
}

const PagoProblemas = withTranslation('common')(PagoProblemasTranslated);
export default PagoProblemas;