import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import isAutenticated from '../components/servicios/autenticacion';



/**
 * Crea una ruta publica
 * @param {component, options} param0 
 */
export const PublicRoute = ({ component, ...options }) => {
    const isAuth = isAutenticated();
    if (!isAuth) return <Route {...options} component={component} />
    return <Redirect to="/" />

}

/**
 * Crea una ruta privada
 * @param {component, options} param0 
 */
export const PrivateRoute = ({ component, ...options }) => {
    const isAuth = isAutenticated();
    if (isAuth) return <Route {...options} component={component} />
    return <Redirect to="/" />

}