import { PushNotifications } from '@capacitor/push-notifications';
import { LocalNotifications } from '@capacitor/local-notifications';
import { getPlatforms } from '@ionic/react';
import api from '../../components/servicios/api';

let userData;
async function enviarToken(tokenregistro) {
    const cliente = JSON.parse(localStorage.getItem('user'));
    let dispositivo = getPlatforms();
    await api.Clientes.envioToken(cliente.cif, tokenregistro, dispositivo).then(
        ({ data }) => (userData = data)
    );
    console.log("envio del token a bd   " + userData);
}

async function Connect() {
    /*const registerNotifications = async () => {
        let permStatus = await PushNotifications.checkPermissions();

        if (permStatus.receive === 'prompt') {
            permStatus = await PushNotifications.requestPermissions();
        }

        if (permStatus.receive !== 'granted') {
            console.log('Sin permiso');
        } else {
            await PushNotifications.register();
        }


    }*/

    console.log('Initializing HomePage');
    await PushNotifications.checkPermissions().then((res) => {
        if (res.receive !== 'granted') {
            PushNotifications.requestPermissions().then(result => {
                console.log('Solicitar permisos');
                if (result.granted) {
                    // Register with Apple / Google to receive push via APNS/FCM
                    PushNotifications.register();

                } else {
                    console.log('Sin permisos');
                }
            });

        } else {
            PushNotifications.register();
        }
    })


    PushNotifications.createChannel({
        id: '1',
        name: 'default',
        description: '',
        sound: '',
        importance: 1,
        visibility: 1,
        lights: true,
        lightColor: "green",
        vibration: true,
    });

    /*const addListeners = async () => {
        await PushNotifications.addListener('registration', token => {
          console.info('Registration token: ', token.value);
        });
      
        await PushNotifications.addListener('registrationError', err => {
          console.error('Registration error: ', err.error);
        });
      
        await PushNotifications.addListener('pushNotificationReceived', notification => {
          console.log('Push notification received: ', notification);
        });
      
        await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
          console.log('Push notification action performed', notification.actionId, notification.inputValue);
        });
      }*/

    // On succcess, we should be able to receive notifications
    await PushNotifications.addListener('registration',
        (token) => {
            console.log('Push registration success, token: ' + token.value);
            enviarToken(token.value);
        }
    );

    // Some issue with our setup and push will not work
    await PushNotifications.addListener('registrationError',
        (error) => {
            console.log('Error on registration: ' + JSON.stringify(error))
            //alert('Error on registration: ' + JSON.stringify(error));
        }
    );

    // Show us the notification payload if the app is open on our device
    await PushNotifications.addListener('pushNotificationReceived',
        (notification) => {
            console.log('Notificacion : ' + JSON.stringify(notification))
            LocalNotifications.schedule({
                notifications: [
                    {
                        title: notification.title,
                        body: notification.body,
                        id: 1,
                        extra: {
                            data: notification.data
                        }
                    }]
            })
        }
    );

    // Method called when tapping on a notification
    await PushNotifications.addListener('pushNotificationActionPerformed',
        (notification) => {
            console.log('Push action performed: ' + JSON.stringify(notification))
            window.location = "/Login";
        }
    );

    await LocalNotifications.addListener('localNotificationActionPerformed',
        (notification) => {
            console.log('push action performed en primer plano:', notification);
            window.location = "/Login";
        });

    const getDeliveredNotifications = async () => {
        const notificationList = await PushNotifications.getDeliveredNotifications();
        console.log('delivered notifications', notificationList);
    }



}


const Push = {
    con() {
        return Connect();
    }
}

export default Push;
