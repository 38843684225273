import { Contact, Contacts, NewContact } from '@capacitor-community/contacts';
import { getPlatforms } from '@ionic/react';
import api from '../../components/servicios/api';

let userData;
async function enviarToken(tokenregistro) {
    const cliente = JSON.parse(localStorage.getItem('user'));
    let dispositivo = getPlatforms();
    await api.Clientes.envioToken(cliente.cif, tokenregistro, dispositivo).then(
        ({ data }) => (userData = data)
    );
    console.log("envio del token a bd   " + userData);
}

async function Connect() {

    console.log('antes de solicitar permisos ');
    const perm = await Contacts.getPermissions().then((res) => {
        console.log('Solicitar permisos');
        if (res.receive != 'denied') {

            Contacts.getContacts().then(result => {
                console.log('contactos result')
                console.log(JSON.stringify(result['contacts']));
                return result['contacts'];
                /*for (let index = 0; index < result['contacts'].length; index++) {
                    const element = result['contacts'][index];
                   
                    console.log(JSON.stringify(element.displayName));
                }*/


            });

            //saveContact();




        } else {
            console.log('error');
        }
    });


    async function saveContact() {
        const newContact = {
            givenName: "Arthur",
            familyName: "Dent"
        }

        Contacts.saveContact(newContact)
        const toast = await this.toastController.create({
            message: `${newContact.givenName} saved`,
            duration: 2000
        });
        toast.present();
    }


    // Method called when tapping on a notification
    /* PushNotifications.addListener('pushNotificationActionPerformed',
         (notification) => {
             console.log('Push action performed: ' + JSON.stringify(notification))
             window.location = "/Login";
         }
     );*/



}




const Contactos = {
    con() {
        return Connect();
    }
}

export default Contactos;
