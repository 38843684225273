import React from 'react';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import DocumentosFirma from '../../components/Documentos/DocumentosFirma';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import Alertas from '../../components/servicios/Alertas';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import PiePagina from '../../components/Navbar/PiePagina';
import "../../styles/GlobalPages.css";
import imagenTicket from '../../Imagenes/imagenTicket.png';
import { Camera, CameraResultType } from '@capacitor/camera';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';

let userData;
let documentos = [];
let blob11;
let resultC;

class DocumentosTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: undefined,
        sinDatos: false,
        accesoMaestro: false,
        photo: '',
        alertOk: false,
        alertKo: false

    };
    componentDidMount() {
        const cliente = JSON.parse(localStorage.getItem('user'));
        documentos = [];
        this.fetchData();
        if (cliente.cif == process.env.REACT_APP_USUARIO_MAESTRO) {
            this.setState({ loading: false, accesoMaestro: true });
        }
    }

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const idioma = this.props.i18n.language;
        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.documentosFirma(`&cliente=${cliente.usuario}`).then(
                ({ data }) => (userData = data)
            );

            if (Array.isArray(userData)) {
                for (let i = 0; i < userData.length; i++) {
                    documentos.push(userData[i]);
                }
                this.setState({ loading: false, datos: userData });
            } else if (userData == "sin datos" || userData == "No se han encontrado datos para el modelo <b>get_documentos_firmar</b>") {
                this.setState({ loading: false, sinDatos: true });
            } else {
                this.setState({ loading: false, error: true });
                Alertas.sinConexion(idioma);
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    subirDocu = async () => {
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.subirDocu(blob11).then(
                ({ data }) => (resultC = data)
            );
            if (resultC == 'OK') {
                this.setState({ loading: false });
                this.abrirAlertOk();
            } else {
                this.setState({ loading: false });
                this.abrirAlertKo();
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }

    };


    async takePicture() {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri
        });
        let imageUrl = image.webPath;
        blob11 = await fetch(image.webPath).then(r => r.blob()).catch(function (err) {
            //console.log(err);
        });
        this.setState({
            photo: imageUrl
        })


        if (blob11.size > 4194304) {
            Alertas.demasiadoGrande();
            this.setState({
                photo: ''
            })
        }

    }



    render() {
        const { t } = this.props;

        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return (
                <div>
                    <Pageinfo datos={'Documentos'} />

                    {this.state.accesoMaestro &&
                        <div className="container h-100">
                            <div className='subir-doc'>
                                <div className='titulo-subir-doc'>
                                    Subir documentación
                                </div>
                                <div className="imagen">
                                    <br></br>
                                    {this.state.photo.length > 0 ?
                                        <img className="sinImagen"
                                            src={this.state.photo}
                                            alt="sinImagen"
                                            onClick={() => this.takePicture()}
                                        /> : <img className="imagenTicket"
                                            src={imagenTicket}
                                            alt="sinImagen"
                                            onClick={() => this.takePicture()}
                                        />}
                                </div>
                                <button className='btn-aceptar' onClick={() => this.subirDocu()}> Subir documento</button>



                            </div>
                            <hr />
                            <div className='subir-doc'>
                                Firma de  documentación
                            </div>

                        </div>}

                </div>
            )
        }
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{t('Documentos.titulo',)}</span>
                </div>
                {this.state.accesoMaestro &&
                    <div>
                        <div className='subir-doc'>
                            <div className='titulo-subir-doc'>
                                Subir documentación
                            </div>
                            <div className="imagen">
                                <br></br>
                                {this.state.photo.length > 0 ?
                                    <img className="sinImagen"
                                        src={this.state.photo}
                                        alt="sinImagen"
                                        onClick={() => this.takePicture()}
                                    /> : <img className="imagenTicket"
                                        src={imagenTicket}
                                        alt="sinImagen"
                                        onClick={() => this.takePicture()}
                                    />}
                            </div>
                            <button className='btn-aceptar' onClick={() => this.subirDocu()}> Subir documento</button>



                        </div>
                        <hr />
                        <div className='subir-doc'>
                            Firma de  documentación
                        </div>
                    </div>}
                <div className="lista">
                    <ul className="list-unstyled">
                        {documentos.map(documento => {
                            return (
                                <li key={documento.id}>
                                    <DocumentosFirma
                                        id={documento.id}
                                        descripcion={documento.descripcion}
                                        url={documento.url}
                                    />
                                </li>
                            );
                        })}
                    </ul>
                    {this.state.loading && <MiniLoader />}
                </div>
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('Clientes.alertaOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('Clientes.alertako',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

                <PiePagina />
            </div>
        );
    };
}
const Documentos = withTranslation('common')(DocumentosTranslated)
export default Documentos;