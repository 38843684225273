import React from 'react';
import { withTranslation } from "react-i18next";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import error from '../../Imagenes/error.png';

class PagoIncorrectoTranslated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="error-pago-text">
                    <span> {t('PagoError.disculpa')}</span>
                </div>
                <div className="error-pago">
                    <img className="error-pago-img" src={error} alt="Logotipo" />
                </div>
                <div className="error-pago-info">
                    {t('PagoError.info')}
                </div>
            </div>
        )

    }
}

const PagoIncorrecto = withTranslation('common')(PagoIncorrectoTranslated);
export default PagoIncorrecto;