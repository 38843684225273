import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import { withTranslation } from "react-i18next";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import Descuento from '../../components/Descuentos/Descuento';


let userConsumo;

class DescuentosTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
    };
    componentDidMount() {
        userConsumo = [];
        this.fetchData();
    }




    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.consultarDescuentos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });

            if (!Array.isArray(userConsumo)) {
                this.setState({ loading: false });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };


    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'sinDDi'} />
        }

        return (
            <div className="container h-100 float-left" >
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{t('Descuentos.titulo',)}</span>
                </div>
                {this.state.datos.map(dato => {
                    return (
                        <Descuento descuento={dato} />
                    )
                })}

            </div >
        );
    };

}
const Descuentos = withTranslation('common')(DescuentosTranslated)
export default Descuentos;