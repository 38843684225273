import React from 'react';
import { Switch, Redirect, Route } from 'react-router';
import { PrivateRoute } from './helperRoutes';
import Home from '../pages/Home/Home';
import ComentarioNuevo from '../pages/Tickets/ComentarioNuevo';
import contrasenia from '../pages/Cliente/contrasenia';
import TarifasDetalle from '../pages/Tarifas/TarifasDetalle';
import DatosCliente from '../pages/Cliente/DatosCliente';
import Consumo from '../pages/Consumo/Consumo';
import Contratos from '../pages/Contratos/Contratos';
import Facturas from '../pages/Facturas/Facturas';
import Tickets from '../pages/Tickets/Tickets';
import TicketNew from '../pages/Tickets/TicketNew';
import TicketDetalle from '../pages/Tickets/TicketDetalle';
import ContratoDetalle from '../pages/Contratos/ContratoDetalle';
import AcercaDe from '../pages/Cliente/AcercaDe';
import Tarifas from '../pages/Tarifas/Tarifas';
import Documento from '../pages/Documentos/Documentos';
import ConsumoDetalle from '../pages/Consumo/ConsumoDetalle';
import Login from '../pages/Index/Login';
import Noticias from '../pages/Noticias/Noticias';
import FormasPago from '../pages/Facturas/FormasPago';
import PagoIncorrecto from '../pages/Facturas/PagoIncorrecto';
import PagoCorrecto from '../pages/Facturas/PagoCorrecto';
import PagoProblemas from '../pages/Facturas/PagoProblemas';
import Recargas from '../pages/Recargas/Recargas';
import Herramientas from '../pages/Herramientas/Herramientas';
import Agenda from '../pages/Agendas/Agendas';
import Descuentos from '../pages/Descuentos/Descuentos';
import Redsys from '../pages/Facturas/PagoRedsys';
import Bizum from '../pages/Facturas/PagoBizum';
import Clientes from '../pages/Clientes/Clientes';
import ClientesPotenciales from '../pages/Clientes/ClientesPotenciales';
import Cliente from '../pages/Clientes/Cliente';
import CrearCliente from '../pages/Clientes/CrearCliente';
import Ofertas from '../pages/Ofertas/Ofertas';
import NuevaOferta from '../pages/Ofertas/NuevaOferta';
import NuevaCita from '../pages/Citas/NuevaCita';
import Contratacion from '../pages/Contratacion/Contratacion';
import Usuarios from '../pages/Usuarios/Usuarios';
import Alertas from '../pages/Alertas/Alertas';
import Alerta from '../pages/Alertas/Alerta';




/**
 * Rutas privadas 
 * @param {*} props 
 */
const PrivateRoutes = (props) => {
    return (
        <Switch>
            <PrivateRoute exact path="/Home" component={Home} />
            <PrivateRoute exact path="/Home/:idioma" component={Home} />
            <PrivateRoute exact path="/contrasenia" component={contrasenia} />
            <PrivateRoute exact path="/Comentarios" component={ComentarioNuevo} />
            <PrivateRoute exact path="/Login" component={Login} />
            <PrivateRoute exact path="/Datos" component={DatosCliente} />
            <PrivateRoute exact path="/Consumo" component={Consumo} />
            <PrivateRoute exact path="/Contratos" component={Contratos} />
            <PrivateRoute exact path="/Facturas" component={Facturas} />
            <PrivateRoute exact path="/Tickets" component={Tickets} />
            <PrivateRoute exact path="/Tickets/:ticketId" component={TicketDetalle} />
            <PrivateRoute exact path="/TicketNew" component={TicketNew} />
            <PrivateRoute exact path="/Contratos/:contratoId" component={ContratoDetalle} />
            <PrivateRoute exact path="/AcercaDe" component={AcercaDe} />
            <PrivateRoute exact path="/Tarifas" component={Tarifas} />
            <PrivateRoute exact path="/Documento" component={Documento} />
            <PrivateRoute exact path="/ConsumoDetalle" component={ConsumoDetalle} />
            <PrivateRoute exact path="/Noticias" component={Noticias} />
            <PrivateRoute exact path="/TarifasDetalle" component={TarifasDetalle} />
            <PrivateRoute exact path="/FormasPago" component={FormasPago} />
            <PrivateRoute exact path="/PagoIncorrecto" component={PagoIncorrecto} />
            <PrivateRoute exact path="/PagoCorrecto" component={PagoCorrecto} />
            <PrivateRoute exact path="/PagoProblemas" component={PagoProblemas} />
            <PrivateRoute exact path="/Recargas" component={Recargas} />
            <PrivateRoute exact path="/Herramientas" component={Herramientas} />
            <PrivateRoute exact path="/Agenda" component={Agenda} />
            <PrivateRoute exact path="/Descuentos" component={Descuentos} />
            <PrivateRoute exact path="/PagoBizum" component={Bizum} />
            <PrivateRoute exact path="/PagoRedsys" component={Redsys} />
            <PrivateRoute exact path="/Clientes" component={Clientes} />
            <PrivateRoute exact path="/ClientesPotenciales" component={ClientesPotenciales} />
            <PrivateRoute exact path="/Cliente" component={Cliente} />
            <PrivateRoute exact path="/CrearCliente" component={CrearCliente} />
            <PrivateRoute exact path="/Ofertas" component={Ofertas} />
            <PrivateRoute exact path="/NuevaOferta" component={NuevaOferta} />
            <PrivateRoute exact path="/NuevaCita" component={NuevaCita} />
            <PrivateRoute exact path="/Contratacion" component={Contratacion} />
            <PrivateRoute exact path="/Usuarios" component={Usuarios} />
            <PrivateRoute exact path="/Alertas" component={Alertas} />
            <PrivateRoute exact path="/Alerta" component={Alerta} />


            < Route exact path="*" render={() => {
                return < Redirect to="/Home" />
            }} />

        </Switch>
    )

}

export default PrivateRoutes;