import React from 'react';
import { IonAlert } from '@ionic/react';
import Pregunta from '../../Imagenes/pregunta.png';
import { withTranslation } from "react-i18next";
import api from '../servicios/api';
import Ok from '../../Imagenes/ok.png';
import Error from '../../Imagenes/error.png';
import PageError from "../PantallaCarga/PageError";
import PageLoading from "../PantallaCarga/PageLoading";
import Util from '../servicios/Util';
import { cloudDone } from 'ionicons/icons';


let respuesta = '';
let codAsunto = '';
let mostrar_ticket = '';
let mensaje = '';

class AceptarContactoTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        alert: true,
        alertOk: false,
        alertKo: false
    }

    componentDidMount() {
        console.log(this.props)
    }


    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }

    contactar = async () => {
        this.setState({ loading: true, error: null, });
        const contacto = this.props.dato
        const appConf = JSON.parse(localStorage.getItem("appconf"));

        try {
            if (appConf == null || appConf == '' || appConf == '') {

                await api.Clientes.configApp().then(
                    ({ data }) => (respuesta = data)
                ).catch(function (err) {
                    console.log(err);
                });

                if (Array.isArray(respuesta)) {

                    codAsunto = respuesta[0].asunto_interesa_ticket;
                    mostrar_ticket = respuesta[0].mostrar_ticket_interesa_tarifa;
                    mensaje = respuesta[0].mensaje_interesa_ticket;

                } else {
                    this.setState({ loading: false, error: null, alert: false });
                    this.abrirAlertKo();
                }
                console.log("if")
            } else {
                codAsunto = appConf.asunto_ticket;
                mostrar_ticket = appConf.mostrar_ticket;
                mensaje = appConf.mensaje;
            }


        } catch (e) {
            console.log(e);
            this.setState({ loading: false, error: e });
        }

        if (contacto == "contacto") {
            mensaje = "Cliente interesado en promoción fija";
        } else {
            mensaje = Util.strip(mensaje);
            mensaje = Util.mensajeTicket(mensaje, contacto.id, contacto.nombre);

        }
        const cliente = JSON.parse(localStorage.getItem('user'));
        await api.Clientes.nuevoTicket(cliente.usuario, 5, codAsunto, mensaje, mostrar_ticket).then(
            ({ data }) => (respuesta = data)
        );
        if (isNaN(parseInt(respuesta))) {
            this.setState({ loading: false, error: null, alert: false });
            this.abrirAlertKo();
        } else {
            this.setState({ loading: false, error: null, alert: false });
            this.abrirAlertOk();
        }

    }

    render() {
        const { t } = this.props;
        const contacto = this.props.dato
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.loading) {
            return <PageLoading />;
        }
        return (
            <div>
                <IonAlert
                    isOpen={this.state.alert}
                    cssClass='correcto info'
                    header={t('TarifaBloque.info',)}
                    message={`<img src="${Pregunta}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.cance',)}`],
                        cssClass: 'info',
                        handler: () => {
                            window.location.reload();
                        }

                    },
                    {
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            this.contactar();
                        }
                    }
                    ]}
                />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('TarifaBloque.ticketOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            if (contacto != "contacto") {
                                window.location = '/Tarifas';
                            }
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('TarifaBloque.ticketko',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            if (contacto != "contacto") {
                                window.location = '/Tarifas';
                            }

                        }
                    }]}
                />
            </div>
        )
    }
}
const AceptarContacto = withTranslation('common')(AceptarContactoTranslated)
export default AceptarContacto;