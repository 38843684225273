import React, { useEffect } from "react";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import classNames from 'classnames/bind';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
/*import { File } from "@ionic-native/file";
import { FileOpener } from "@ionic-native/file-opener";*/
import Moment from 'react-moment';
import { isPlatform } from '@ionic/react';
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../../components/PantallaCarga/PageError";
import Alertas from '../../components/servicios/Alertas';
import { Link } from 'react-router-dom';


let respuesta;
const BASE_URL = process.env.REACT_APP_API_PRO
//const BASE_URL = process.env.REACT_APP_API_DEV

class FacturasListItemTranslated extends React.Component {

  state = {
    loading: false,
    error: false,
    pagar: null,
    restoPago: null
  };



  componentDidMount() {
    const estado = this.props.factura.situacion;
    const importe = this.props.factura.total;
    let importePositivo = importe.includes('-');
    this.obtenerResto();
    const appConf = JSON.parse(localStorage.getItem('funciones'));
    if (appConf.pagar_facturas == 0) {
      this.setState({ pagar: false });
    } else {
      if (estado == '0' && importePositivo == false) {
        this.setState({ pagar: true });
      } else {
        this.setState({ pagar: false });
      }
    }


  }

  click_factura = () => {
    this.setState({ loading: true, error: null });
    this.fetchData();
  }

  obtenerResto = async () => {
    this.setState({ loading: true, error: null });
    const idfactura = this.props.factura.id;
    try {
      await api.Clientes.restoFactura(idfactura).then(
        ({ data }) => (respuesta = data)
      );
      if (respuesta != "sin datos") {

        this.setState({ loading: false, restoPago: respuesta });
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  }

  fetchData = async () => {
    //this.setState({ loading: true, error: null });

    try {
      const cliente = JSON.parse(localStorage.getItem('user'));
      const id = this.props.factura.id;

      if (isPlatform('android')) {
        await api.Clientes.descargaFacturasAndroid(id, cliente.usuario).then((response) => {
          var popout = window.open(`${BASE_URL}/facturas/factura${id}.pdf`);
          if (response == undefined || response == 'Error: 22' || response == 'Error: 35') {
            this.setState({ loading: false, error: true });
          }
        });

        this.setState({ loading: false, error: null });

      } else if (isPlatform('ios')) {
        /*api.Clientes.descargaFacturas(id, cliente.usuario).then((response) => {
          const fileName = "factura" + "_" + id + ".pdf";
          if (response == undefined || response == 'Error: 22' || response == 'Error: 35') {
            this.setState({ loading: false, error: true });
          }
          File.writeFile(
            File.documentsDirectory,
            fileName,
            new Blob([response.data], { type: 'application/pdf' }),
            {
              replace: true,
            }
          ).then(() => {
            const fileName = "factura" + "_" + id + ".pdf";
            FileOpener.open(
              File.documentsDirectory + fileName,
              "application/pdf"
            );

          }).catch(() => {
          }).finally(() => {
            this.setState({ loading: false, error: null });
          }
          );
        }).catch(() => {
          const idioma = this.props.i18n.language;
          Alertas.despues(idioma);
        }).finally(() => {
          this.setState({ loading: false, error: null });
        });*/

      } else {
        await api.Clientes.descargaFacturasAndroid(id, cliente.usuario).then((response) => {
          var popout = window.open(`${BASE_URL}/facturas/factura${id}.pdf`);
          if (response == undefined || response == 'Error: 22' || response == 'Error: 35') {
            this.setState({ loading: false, error: true });
          }
        });

        this.setState({ loading: false, error: null });

      }

    } catch (e) {
      console.log(e);
      this.setState({ loading: false, error: true });
    }
  }



  render() {
    const { t } = this.props;
    let btnClass = classNames({
      'btn': 0,
      'btn-pressed': 2,
      'btn-over': 1
    });
    let facClass = 'btn-over-fac';
    if (this.state.error) {
      return <PageError />;
    }
    if (this.props.factura.situacion) btnClass += 'btn-pressed';
    else if (this.props.factura.situacion) btnClass += 'btn-over';
    if (this.props.factura.situacion == 0) {
      facClass = 'btn-pressed-fac';
    }
    if (this.state.loading === true) {
      return <PageLoading />;
    } else {
      return (
        <div>
          <div className={facClass} >
            <div className="num-factura">
              <div className="descarga" onClick={() => this.click_factura()}>
                <span className="float-right">
                  {t('Facturas.descarga',)}
                  <FontAwesomeIcon icon="file-arrow-down" className='icon' />
                </span>
              </div>
              <FontAwesomeIcon icon="file-invoice-dollar" className={btnClass} />
              <span className={btnClass}> </span>
              <span>{t('Facturas.factura',)} </span>
              {this.props.factura.factura}
            </div>
            <div className="fecha-factura">
              <span>{t('DetalleConsumo.fecha',)} </span>
              <Moment format="DD/MM/YY" className="fecha">{this.props.factura.fecha}</Moment>
            </div>
            <div className={this.state.pagar ? 'btnfactura' : 'hidden'}>
              <Link
                to={{
                  pathname: `/FormasPago/`,
                  state: {
                    factura: `${this.props.factura.id}`,
                    importe: `${this.state.restoPago}`,
                    contrato: false,
                    fechainicio: false,
                    fechafin: false
                  }
                }}>
                <p className="link-pagar">
                  <FontAwesomeIcon icon="cart-shopping" className='icon' />
                  {t('Facturas.pagar',)}</p>
              </Link>
            </div>
            <div className="importe-factura">
              <span>{t('Facturas.importe',)}</span>
              {this.props.factura.total}
              <span> € </span>
            </div>

            <div className={this.state.pagar ? 'pagar' : 'hidden'}>
              {t('Facturas.resto',)} {this.state.restoPago} €
            </div>
            {this.state.loading && <MiniLoader />}
          </div>

        </div>
      );
    }
  }
}
const FacturasListItem = withTranslation('common')(FacturasListItemTranslated)

function useSearchFacturas(facturas) {
  const [list, setList] = React.useState(facturas);


  useEffect(() => {
    const sortedList = [...list].sort(function (a, b) {
      var dateA = new Date(a.fecha).getTime();
      var dateB = new Date(b.fecha).getTime();
      return dateB > dateA ? 1 : -1;
    });
    setList(sortedList)
  }, [])
  const [query, setQuery] = React.useState("");
  const [fileteredFacturas, setfileteredFacturas] = React.useState(list);


  React.useMemo(() => {
    const result = list.filter((factura) => {
      return `${factura.factura} ${factura.total}`
        .toLowerCase()
        .includes(query.toLowerCase());
    });

    setfileteredFacturas(result);
  }, [list, query]);

  return { query, setQuery, fileteredFacturas };
}




function FacturasListTranslated(props) {
  const [t, i18n] = useTranslation('common');
  const facturas = props.facturas;
  const { query, setQuery, fileteredFacturas } = useSearchFacturas(facturas);





  if (fileteredFacturas.length === 0) {

    return (
      <div>
        <div className="div-buscar">
          <FontAwesomeIcon icon="magnifying-glass" className="icon" />
          <input
            type="text"
            className="form-control"
            value={query}
            onChange={e => {
              setQuery(e.target.value);
            }}
          />
        </div>
        <h5>{t('Facturas.info',)} </h5>
      </div>
    );
  }

  return (
    <div className="facturasList">
      <div className="div-buscar">
        <FontAwesomeIcon icon="magnifying-glass" className="icon" />
        <input
          type="text"
          className="input-buscar"
          placeholder={t('Contratos.buscar',)}
          value={query}
          onChange={e => {
            setQuery(e.target.value);
          }}
        />
      </div>
      <ul className="list-unstyled">

        {fileteredFacturas.map(factura => {
          return (
            <li key={factura.id}>
              <FacturasListItem factura={factura} />
            </li>
          );
        })}
      </ul>
    </div>
  );

}
const FacturasList = withTranslation('common')(FacturasListTranslated)
export default FacturasList;
