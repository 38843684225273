import React, { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";
import api from "../../components/servicios/api";
let resIsp;

const PagoBizum = (props) => {
    const query = new URLSearchParams(useLocation().search);

    const params = query.get('Ds_MerchantParameters');
    const firma = query.get('Ds_Signature');

    useEffect(() => {
        fetchData()
            .then(items => {

            })
    }, []);


    const fetchData = async () => {
        await api.Clientes.capturaPagoRedsys(params, firma, 1).then(
            ({ data }) => (resIsp = data)
        ).catch(function (err) {
            console.log(err);
        });

        if (resIsp == 'Pago registrado correctamente = 1') {
            window.location = '/PagoCorrecto';
        } else {
            window.location = '/PagoProblemas';
        }

    };


    return (
        <>
        </>
    )
}
export default PagoBizum