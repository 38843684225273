import React, { Suspense } from 'react';
import PublicRoutes from '../routes/publicRoutes';
import PrivateRoutes from '../routes/privateRoutes';
import isAutenticated from './servicios/autenticacion';
import DeepLink from '../components/Facturas/DeepLink';


function App(props) {
  const isAuth = isAutenticated();

  return (

    <div>
      <Suspense fallback="loading">
        <DeepLink></DeepLink>
        {
          isAuth
            ? <PrivateRoutes props={props} />
            : <PublicRoutes />
        }
      </Suspense>
    </div>



  );
}

export default App;
