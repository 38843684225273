import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';

class AcercaDeTranslated extends React.Component {
    state = {

        nombreCompleto: '',
        nombre: '',
        email: '',
        acerca: '',
        telf: ''
    };



    componentDidMount() {
        const datosempresa = JSON.parse(localStorage.getItem("empresa"));
        const acerca_de = JSON.parse(localStorage.getItem("appconf"));
        if (datosempresa == null && acerca_de == null) {


        } else {
            this.setState({
                nombre: datosempresa.nombreComercial,
                nombreCompleto: datosempresa.nombre,
                email: datosempresa.email,
                acerca: acerca_de.acerca_de,
                telf: datosempresa.telefono
            });

        }
    }


    render() {
        const { t } = this.props;
        if (this.state.nombre == undefined || this.state.nombreCompleto == undefined || this.state.email == undefined || this.state.acerca == undefined || this.state.telf == undefined) {
            return (
                <div className="container h-100">
                    <div className="nav-navegacion">
                        <NavbarVariable datos={'/Datos'} />
                    </div>
                    <div className="titulo-pagina">
                        <span>{t('AcercaDe.titulo',)} </span>
                    </div>
                    <div className="version">
                        <div className="num-version">
                            <FontAwesomeIcon icon="mobile-screen-button" className="icon" />
                            <span>     v.1.0.21</span>
                        </div>
                        <div className="email">
                            <FontAwesomeIcon icon="at" className="icon" />
                            <a href={`mailto:`} >  </a>
                        </div>
                        {process.env.REACT_APP_APP_NAME == 'WifiSanctipetri' ?
                            <div className="email">
                                <FontAwesomeIcon icon="at" className="icon" />
                                <a href={`mailto:administracion@wifisanctipetri.es`} >administracion@wifisanctipetri.es  </a>
                            </div> : ''}
                        {process.env.REACT_APP_APP_NAME == 'AirConect' ?
                            <div className="web">
                                <FontAwesomeIcon icon="wifi" className="icon" />
                                <a href="https://airconet.es/" >www.airconect.es  </a>
                            </div> : ''}
                    </div>
                    <PiePagina />
                </div >
            )
        }
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Datos'} />
                </div>
                <div className="titulo-pagina">
                    <span>{t('AcercaDe.titulo',)} {this.state.nombre}</span>
                </div>
                <div className="acerca">
                    <span dangerouslySetInnerHTML={{ __html: this.state.acerca }} />
                </div>
                <div className="version">
                    <div className="num-version">
                        <FontAwesomeIcon icon="mobile-screen-button" className="icon" />
                        <span>     {this.state.nombre}  v.1.0.21</span>
                    </div>
                    <div className="email">
                        <FontAwesomeIcon icon="at" className="icon" />
                        <a href={`mailto:${this.state.email}`} >{this.state.email}  </a>
                    </div>
                    <div className="tlf">
                        <FontAwesomeIcon icon="phone" className="icon" />
                        <a href={`tel:${this.state.telf}`}>{t('AcercaDe.llamanos',)} {this.state.telf}</a>
                    </div>
                    {process.env.REACT_APP_APP_NAME == 'WifiSanctipetri' ?
                        <div className="email">
                            <FontAwesomeIcon icon="at" className="icon" />
                            <a href={`mailto:administracion@wifisanctipetri.es`} >administracion@wifisanctipetri.es  </a>
                        </div> : ''}
                    {process.env.REACT_APP_APP_NAME == 'AirConect' ?
                        <div className="web">
                            <FontAwesomeIcon icon="wifi" className="icon" />
                            <a href="https://airconet.es/" >www.airconect.es  </a>
                        </div> : ''}
                </div>
                <PiePagina />
            </div >
        )
    }
}
const AcercaDe = withTranslation('common')(AcercaDeTranslated)
export default AcercaDe;