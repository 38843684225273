import { NativeBiometric } from 'capacitor-native-biometric';

const idioma = JSON.parse(localStorage.getItem('idioma'));

function noConecta(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "No es posible conectar en este momento";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "No és possible connectar en aquest moment";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "Unable to connect at this time";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    // alert.header = 'Alert';
    //alert.subHeader = 'Subtitle';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function usuariobaja(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "El usuario esta en baja";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "L'usuari està en baixa";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "User is unsubscribed";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function userMal(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "El usuario o la contraseña no es correcta";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "L'usuari o la contrasenya no és correcta";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "The username or password is not correct";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}
function sinUser(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "Debe indicar su número de NIF";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "Ha d'indicar el seu número de NIF";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "You must indicate your NIF number";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}
function emailOk(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "Le hemos enviado un email con su contraseña de acceso";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "Li hem enviat un email amb la contrasenya d'accés";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "We have sent you an email with your access password";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function emailNo(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "No disponemos de ningún email válido para enviarte la contraseña de acceso";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "No disposem de cap email vàlid per a enviar-te la clau d'inscripció";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "We do not have any valid email to send you the access password";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function userNoEx(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "El número  de NIF introducido no corresponde a ningún cliente";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "El nombre de NIF introduït no correspon a cap client";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "The VAT number entered does not correspond to any customer";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function masTarde(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "No ha sido posible realizar la operación, inténtalo de nuevo más tarde";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "No ha estat possible realitzar l'operació, intenta-de nou més tard";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "The operation was not possible, try again later";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}


function alIndex(lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = "No ha sido posible realizar la operación, inténtalo de nuevo más tarde";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "No ha estat possible realitzar l'operació, intenta-de nou més tard";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "The operation was not possible, try again later";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [{
        text: aceptar,
        handler: () => {
            window.location = '/Login';
        }
    }];

    document.body.appendChild(alert);
    return alert.present();
}
function guardarHuella(lengua) {
    let idioma = lengua;
    let aceptar = "Utilizar";
    let cancelar = "Ahora no";
    let mensaje = "¿Deseas utilizar tu Huella digital para acceder?";
    if (idioma == 'ca') {
        aceptar = "Utilitzar";
        cancelar = "Ara no"
        mensaje = "Voleu utilitzar el teu Empremta per accedir-hi?";
    } else if (idioma == 'in') {
        aceptar = "Use";
        cancelar = "Not now"
        mensaje = "Do you want to use your Fingerprint to access?";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'correcto info';
    alert.header = mensaje;
    // alert.message = `<img src="/../Imagenes/pregunta.png" alt="error" className="errorimg">`;
    alert.buttons = [{
        text: cancelar,
        handler: () => {
            // no se hace nada 
            window.location = '/Home';
        }
    },
    {
        text: aceptar,
        handler: () => {
            // se guarda para iniciar con huella 
            NativeBiometric.isAvailable().then(
                (result) => {
                    const isAvailable = result.isAvailable;
                    const isFaceId = result.biometryType;
                    if (isAvailable) {
                        NativeBiometric.setCredentials({
                            username: "usuario",
                            password: "contraseña",
                            server: process.env.REACT_APP_APP_NAME,
                        }).then();
                        localStorage.setItem("HuellaSi", JSON.stringify(true));
                        console.log("huella puesta ok");
                    }
                    window.location = '/Home';
                },
                (error) => {
                    // No se pudo verificar la disponibilidad
                    console.log(error);
                }
            );

        }
    }];

    document.body.appendChild(alert);
    return alert.present();
}

function verificar(texto, lengua) {
    let idioma = lengua;
    let aceptar = "Aceptar";
    let mensaje = texto;
    if (idioma == 'ca') {
        aceptar = "Acceptar";
    } else if (idioma == 'in') {
        aceptar = "Accept";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'error';
    alert.header = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}

function imagenGrande() {

    let aceptar = "Aceptar";
    let mensaje = "El tamaño máximo de la imagen son 4MB";
    if (idioma == 'ca') {
        aceptar = "Acceptar";
        mensaje = "La mida màxima de la imatge són 4MB";
    } else if (idioma == 'in') {
        aceptar = "Accept";
        mensaje = "The maximum image size is 4MB";
    }
    const alert = document.createElement('ion-alert');
    alert.cssClass = 'avisos';
    alert.message = mensaje;
    alert.buttons = [aceptar];

    document.body.appendChild(alert);
    return alert.present();
}




const Alertas = {
    demasiadoGrande() {
        return imagenGrande();
    },
    sinConexion(lengua) {
        return noConecta(lengua);
    },
    baja(lengua) {
        return usuariobaja(lengua);
    },
    userNo(lengua) {
        return userMal(lengua);
    },
    userVacio(lengua) {
        return sinUser(lengua);
    },
    emailEnviado(lengua) {
        return emailOk(lengua);
    },
    sinEmail(lengua) {
        return emailNo(lengua);
    },
    usuarioNoEx(lengua) {
        return userNoEx(lengua);
    },
    despues(lengua) {
        return masTarde(lengua);
    },
    volver(lengua) {
        return alIndex(lengua);
    },
    verificarPass(texto, lengua) {
        return verificar(texto, lengua);
    },
    Huella(lengua) {
        return guardarHuella(lengua);
    }


};
export default Alertas;