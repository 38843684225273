import React from "react";
import { withTranslation } from "react-i18next";
import Paypal from '../../components/Facturas/PaypalPago';
import Tarjetas from '../../Imagenes/tarjetas.png';
import api from "../../components/servicios/api";
import PageError from "../../components/PantallaCarga/PageError";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import Pageinfo from "../../components/PantallaCarga/Pageinfo";
import Stripe from "../../components/Facturas/StripePago";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import BizumImagen from '../../Imagenes/Bizum.png';
import StripeImagen from '../../Imagenes/stripe.png';
import RedsysImagen from '../../Imagenes/redsys.jpg';
import { isPlatform } from '@ionic/react';


let respuesta;
let tokPaypal;
let tokTarjeta;
let tokRedsys;
let arrayDatosPaypal;
let arrayDatosTarjeta;
let pagoRed;
let tokBizum;
let pagoBizum;
let plataforma;

class FormasPagoTranslated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: true,
            error: null,
            paypal: false,
            sinDatos: false,
            tarjeta: false,
            datos: undefined,
            checked: false,
            signature: null,
            parametros: null,
            merchant: '',
            firma: '',
            merchantBizum: '',
            firmaBizum: '',
            verRedsys: false,
            verBizum: false
        }
    }



    componentDidMount() {
        if (isPlatform('android')) {
            plataforma = 'android';
        } else if (isPlatform('ios')) {
            plataforma = 'ios';
        }

        this.fetchData();
        arrayDatosPaypal = [];
        arrayDatosTarjeta = [];
        //console.log(this.props)
    }

    handleChange(checked) {
        this.setState({ checked });
        this.setState({ paypal: false });
    }


    handleChangeSubmit() {
        document.getElementById("form-redsys").submit();
    }

    handleChangeSubmitBizum() {
        document.getElementById("form-bizum").submit();
    }

    fetchData = async () => {
        console.log(this.props.location.state)
        this.setState({ loading: true, error: null });
        const factura = this.props.location.state.factura;
        const importe = this.props.location.state.importe;
        const contrato = this.props.location.state.contrato;
        const bono = this.props.location.state.bono;
        const fechainicio = this.props.location.state.fechainicio;
        const fechafin = this.props.location.state.fechafin;
        const ddi = this.props.location.state.ddi;
        const importesincoma = importe.replace(',', '.');
        const importescoma = importe.replace('.', ',');
        const cliente = JSON.parse(localStorage.getItem('user'));
        tokPaypal = '';
        tokTarjeta = '';
        tokRedsys = '';
        tokBizum = '';

        try {

            await api.Clientes.tokenPaypal().then(
                ({ data }) => (tokPaypal = data)
            );

            await api.Clientes.tokenTarjeta().then(
                ({ data }) => (tokTarjeta = data)
            );

            await api.Clientes.tokenRedsys(factura).then(
                ({ data }) => (tokRedsys = data)
            );

            await api.Clientes.tokenBizum(factura).then(
                ({ data }) => (tokBizum = data)
            );

            if (tokPaypal == "sin datos" && tokTarjeta == "sin datos" && tokRedsys == "sin datos" && tokBizum == "sin datos") {
                this.setState({ loading: false, sinDatos: true });

            } else {
                if (contrato == true) {

                    await api.Clientes.preparaPagoRecargas(factura, importesincoma, cliente.id).then(
                        ({ data }) => (respuesta = data)
                    );

                } else if (bono == true) {
                    await api.Clientes.preparaPagoBono(factura, importe, cliente.id, ddi).then(
                        ({ data }) => (respuesta = data)
                    );
                } else {
                    await api.Clientes.preparaPago(factura, importe, cliente.id).then(
                        ({ data }) => (respuesta = data)
                    );

                }

                //console.log(respuesta);
                if (isNaN(parseInt(respuesta))) {
                    this.setState({ loading: false, sinDatos: true });
                }
                if (tokRedsys != "sin datos") {
                    await api.Clientes.envioPago(factura, importe, 0, plataforma).then(
                        ({ data }) => (pagoRed = data)
                    );
                    if (Array.isArray(pagoRed)) {
                        this.setState({ merchant: pagoRed[0], firma: pagoRed[1], verRedsys: true });
                    }
                }
                if (tokBizum != "sin datos") {
                    await api.Clientes.envioPago(factura, importe, 1, plataforma).then(
                        ({ data }) => (pagoBizum = data)
                    );
                    if (Array.isArray(pagoBizum)) {
                        this.setState({ merchantBizum: pagoBizum[0], firmaBizum: pagoBizum[1], verBizum: true });
                    }
                }
                if (tokPaypal != "sin datos") {
                    arrayDatosPaypal = {
                        "numfactura": factura, "cantidad": importesincoma, "token": tokPaypal, "idCobro": respuesta, "contrato": contrato, "fechainicio": fechainicio,
                        "fechafin": fechafin, check: this.props.location.state.check, meses: this.props.location.state.meses, "bono": bono, "ddi": ddi
                    }
                    this.setState({ loading: false, paypal: true, datos: arrayDatosPaypal });
                }
                if (tokTarjeta != "sin datos") {
                    arrayDatosTarjeta = {
                        "numfactura": factura, "cantidad": importesincoma, "token": tokTarjeta, "idCobro": respuesta, "contrato": contrato, "fechainicio": fechainicio,
                        "fechafin": fechafin, check: this.props.location.state.check, meses: this.props.location.state.meses, "bono": bono, "ddi": ddi
                    }
                    sessionStorage.setItem("toktarjeta", JSON.stringify(tokTarjeta));
                    this.setState({ loading: false, tarjeta: true, datos: arrayDatosTarjeta });
                }
            }
            // console.log(this.state.datos)
            this.setState({ loading: false, error: null });
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };



    render() {
        const sign_payment = {
            redsys_url: 'https://sis-t.redsys.es:25443/sis/realizarPago',
            Ds_SignatureVersion: 'HMAC_SHA256_V1',
        }

        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        if (this.state.sinDatos) {
            return <Pageinfo datos={'FormasPago'} />
        }
        return (
            <div>
                <div className="container h-100 ">
                    <div className="nav-navegacion">
                        <NavbarVariable datos={'/Home'} />
                    </div>
                    <div className="titulo-pagina">
                        <span className=""> {t('FormasPago.titulo',)}</span>
                    </div>
                    <div className="formapago-info">
                        <span>{t('FormasPago.texto',)}</span>
                    </div>
                    <div className={this.state.tarjeta ? 'formapago' : 'hidden'} onClick={() => this.handleChange(true)}>
                        <div className={this.state.checked ? 'hidden' : 'pagotarjeta'}>
                            <img className="img-formapago" src={Tarjetas} alt="Logotipo" />
                            <span>{t('FormasPago.tarjeta',)}</span>
                            <img className="img-formapago" src={StripeImagen} alt="Logotipo" />
                        </div>
                        <div className={this.state.checked ? 'pagotarjeta' : 'hidden'}>
                            <Stripe data={this.state.datos} />
                        </div>
                    </div>
                    <div className={this.state.verRedsys ? 'formapagoRedsys' : 'hidden'} onClick={() => this.handleChangeSubmit(true)}>
                        <div className={this.state.verRedsys ? 'pagotarjeta' : 'hidden'}>
                            <img className="img-formapago" src={Tarjetas} alt="Logotipo" />
                            <span>{t('FormasPago.tarjeta',)}</span>
                            <img className="img-formapago" src={RedsysImagen} alt="Logotipo" />
                        </div>
                        <form name="form-redsys" id="form-redsys" action={sign_payment.redsys_url} method="POST">
                            <input type="hidden" name="Ds_SignatureVersion" value={sign_payment.Ds_SignatureVersion} />
                            <input type="hidden" name="Ds_MerchantParameters" value={this.state.merchant} />
                            <input type="hidden" name="Ds_Signature" value={this.state.firma} />
                        </form>
                    </div>
                    <div className={this.state.verBizum ? 'formapagoRedsys' : 'hidden'} onClick={() => this.handleChangeSubmitBizum(true)}>
                        <div className={this.state.verBizum ? 'pagotarjeta' : 'hidden'}>
                            <img className="img-formapago" src={BizumImagen} alt="Logotipo" />
                        </div>
                        <form name="form-bizum" id="form-bizum" action={sign_payment.redsys_url} method="POST">
                            <input type="hidden" name="Ds_SignatureVersion" value={sign_payment.Ds_SignatureVersion} />
                            <input type="hidden" name="Ds_MerchantParameters" value={this.state.merchantBizum} />
                            <input type="hidden" name="Ds_Signature" value={this.state.firmaBizum} />
                        </form>
                    </div>
                    <div className={this.state.paypal ? 'payment-div' : 'hidden'}>
                        <Paypal data={this.state.datos} />
                    </div>
                </div>

            </div>
        )
    }
}
const FormasPago = withTranslation('common')(FormasPagoTranslated);
export default FormasPago;
