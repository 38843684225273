import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { withTranslation } from "react-i18next";
import PageError from "../PantallaCarga/PageError";
import PageLoading from "../PantallaCarga/PageLoading";
import AceptarContacto from '../../components/Alertas/AceptarContacto';
import { Link } from 'react-router-dom';
import api from '../../components/servicios/api';
let clase;
let icono;
let userConsumo;
class BonosTranslated extends React.Component {
    state = {
        modal: false,
        mostrar: '1',
        error: false,
        alert: false,
        form: {
            telefono: 'Seleccione un número'
        },
    }

    componentDidMount() {
        console.log(this.props)
    }

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }
    abrirAlert = () => {
        this.setState({ alert: true });
    }

    contacto() {
        this.setState({ modal: !this.state.modal, alert: true });
    }

    handleChange = async (e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
        });

        console.log(this.state.form.telefono)

    };

    render() {
        const { t } = this.props;
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.loading) {
            return <PageLoading />;
        }
        return (
            <div className="">
                <div className="div-container-bonos">
                    <div className='lateral-izq'>
                        {this.props.tipo_bono_api == 'DATOS' && <FontAwesomeIcon icon="wifi" className="icon-bonos-datos" />}
                        {this.props.tipo_bono_api == 'SMS' && <FontAwesomeIcon icon="comment-sms" className="icon-bonos-sms" />}
                        {this.props.tipo_bono_api == '' && <FontAwesomeIcon icon="phone" className="icon-bonos-llama" />}
                        <span className="nombre-tarifa-nom">{this.props.nombre}</span>
                        <div className="precio-bono">
                            <strong><span>{this.props.precio}</span><span className="precio-mes-bono">€</span></strong>

                        </div>
                    </div>
                    <div className='lateral-der'>
                        <div className="detalles">
                            <button className="detalles-btn" onClick={this.abrirModal}
                                style={{
                                    background: this.props.color,

                                }}>
                                <span>{t('Contratacion.anadir',)}  </span>
                            </button>
                        </div>
                    </div>
                </div>
                <Modal isOpen={this.state.modal} >
                    <ModalHeader className="modal-header-tarifas"
                        style={{
                            background: this.props.color,
                        }}>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="tarifas-descripcion-ampliada">
                        <span dangerouslySetInnerHTML={{ __html: this.props.ampliacion }} />
                        <hr />
                        <div className='telefono-bono'>
                            <span>Confirme el número de teléfono</span>
                        </div>
                        <div className="">
                            <form onSubmit={this.props.onSubmit}>
                                <div className="select-telefonos-air">
                                    <select name="telefono" onChange={this.handleChange} className="select-tlf-air" value={this.state.form.telefono}>
                                        <option key="0">{t('Consumo.numero',)}</option>
                                        <option key={this.props.ddi}>{this.props.ddi}</option>
                                    </select>
                                </div>
                            </form>
                            {this.state.sinDatos == true ? <div className='sin-datos'><span >{t('Consumo.sinDatos',)}</span></div> : ''}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <span>{t('Contratacion.interes',)}<strong>{this.props.precio}€</strong> {t('Contratacion.interes2',)}</span>
                        {this.state.form.telefono != 'Seleccione un número' && <Link to={{
                            pathname: `/FormasPago/`,
                            state: {
                                factura: this.props.id,
                                importe: this.props.precio,
                                contrato: false,
                                bono: true,
                                fechainicio: '',
                                fechafin: '',
                                ddi: this.state.form.telefono
                            }
                        }} className="btn-bonos-xl">

                            <FontAwesomeIcon icon="cart-shopping" className='icon-white' />
                            <span className='titulo-btn-modal-bono'>{t('Contratacion.contratar',)}</span>
                        </Link>}
                    </ModalFooter>
                </Modal>
                {this.state.alert && <AceptarContacto dato={this.props} />}
            </div>
        )

    }
};

const Bonos = withTranslation('common')(BonosTranslated)
export default Bonos;