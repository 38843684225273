import CryptoJS from 'crypto-js';
import Axios from "axios";

import { getPlatforms } from '@ionic/react';

async function getToken() {

  let token;
  const BASE_URL = process.env.REACT_APP_API_DEV
  let ipUsuario;

  const header = {
    "alg": "HS256",
    "typ": "JWT"
  };

  let fecha = new Date();
  let minActual = fecha.getMinutes();
  let min = minActual + 5; // Número de minutos hasta caducidad
  let fin = new Date();
  fecha.setMinutes(min);
  fin = fecha;
  let hoy = new Date();

  let dispositivo = getPlatforms();

  const secret = "Hoyesdia28deOctubre";

  function base64url(source) {
    // Encode in classical base64
    let encodedSource = CryptoJS.enc.Base64.stringify(source);

    // Remove padding equal characters
    //encodedSource = encodedSource.replace(/=+$/, '');

    // Replace characters according to base64url specifications
    encodedSource = encodedSource.replace(/\+/g, '-');
    encodedSource = encodedSource.replace(/\//g, '_');

    return encodedSource;
  }


  const ip = sessionStorage.getItem('ip');

  if (ip == '' || ip == null || ip == undefined) {

    await Axios.get(BASE_URL + `/obtenerip/`).then(res => {
      let ip = res.data;
      sessionStorage.setItem("ip", ip);
      ipUsuario = ip;
    });

  } else {
    ipUsuario = ip;
  }

  const data = {
    "app": 'OCR',
    "desde": hoy,
    "hasta": fin,
    "ip": ipUsuario,
    "dispositivo": dispositivo
  };


  let stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header));
  let encodedHeader = base64url(stringifiedHeader);
  let stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(data));
  let encodedData = base64url(stringifiedData);
  let signature1 = encodedHeader + "." + encodedData;
  let signature2 = CryptoJS.HmacSHA256(signature1, secret);
  const signature = base64url(signature2);
  token = signature1 + "." + signature;
  return token;

}

const Token = {
  login() {
    return getToken();
  },

};

export default Token;