import api from "../servicios/api";
import md5 from "md5";
import DatosEmpresa from "./DatosEmpresa";
import Alertas from "../servicios/Alertas";
import { NativeBiometric } from 'capacitor-native-biometric';



async function getDatosCliente(user, pass, lengua, token) {
    const idioma = lengua;
    let empresa = await DatosEmpresa.obtener(idioma);
    if (empresa == true) {
        let userData;
        let registro;
        let permisos;
        const datosempresa = JSON.parse(localStorage.getItem("empresa"));
        try {

            await api.Clientes.envioCliente(user.trim().toUpperCase(), md5(pass.trim())).then(
                ({ data }) => (userData = data)
            ).catch(function (err) {
                console.log(err);
            });

            await api.Clientes.registrarApp(user.trim().toUpperCase()).then(
                ({ data }) => (registro = data)
            );

            //console.log(userData);
            if (userData == 'No se encuentra nada con el id ' + user.toUpperCase() || userData == 'pass incorrecta') {
                Alertas.userNo(idioma);
            } else {
                if (!Array.isArray(userData)) {
                    Alertas.sinConexion(idioma);
                } else {

                    let usuario = {
                        "id": userData[0].id,
                        "usuario": userData[0].clienteid,
                        "codigo": userData[0].codigo,
                        "password": md5(pass.trim()),
                        "cif": user,
                        "nombre": userData[0].nombre,
                        "apellidos": userData[0].apellidos,
                        "email": userData[0].email,
                        "telefono": userData[0].telefono1,
                        "movil": userData[0].movil,
                        "fechaFin": userData[0].fecha_fin,
                        "nombre_completo": userData[0].nombrecompleto,
                        "personaFisica": userData[0].personafisica,
                        "sms": userData[0].movil_sms,
                        "esUsuario": userData[0].esusuario,
                        "pass_cambiada": userData[0].cambio_pass_primer_acceso
                    };

                    localStorage.setItem("user", JSON.stringify(usuario));
                    const cliente = JSON.parse(localStorage.getItem('user'));
                    localStorage.setItem("idioma", JSON.stringify(lengua));

                    if (cliente.esUsuario != '' && cliente.esUsuario != null && cliente.esUsuario != undefined) {
                        await api.Clientes.permisosUsuarios(cliente.esUsuario).then(
                            ({ data }) => (permisos = data)
                        ).catch(function (err) {
                            console.log(err);
                        });

                        let permisosApp = [];

                        for (let index = 0; index < permisos.length; index++) {
                            permisosApp.push(permisos[index].modulo);
                        }

                        localStorage.setItem("permisos", JSON.stringify(permisosApp));

                    }

                    if (datosempresa.accesoBaja == 1) {
                        if (cliente.fechaFin != null) {
                            Alertas.baja(idioma);
                        } else {
                            if (token == true) {
                                localStorage.setItem("token", 1);
                            } else {
                                sessionStorage.setItem("token", 1);
                            }
                            //El cliente no tiene fecha de baja y se le permite entrar
                            const Huellasi = JSON.parse(localStorage.getItem('HuellaSi'));
                            if (Huellasi != null) {
                                window.location = '/Home';
                            } else {
                                NativeBiometric.isAvailable().then(
                                    (result) => {
                                        const isAvailable = result.isAvailable;
                                        if (isAvailable) {
                                            Alertas.Huella(idioma);
                                        } else {
                                            window.location = '/Home';
                                        }
                                    },
                                    (error) => {
                                        // No se pudo verificar la disponibilidad
                                        console.log(error);
                                    }
                                );

                            }
                        }
                    } else {
                        if (token == true) {
                            localStorage.setItem("token", 1);
                        } else {
                            sessionStorage.setItem("token", 1);
                        }
                        //La empresa deja entrar con fecha de baja
                        //window.location = '/Home';
                        const Huellasi = JSON.parse(localStorage.getItem('HuellaSi'));
                        if (Huellasi != null) {
                            window.location = '/Home';

                        } else {
                            NativeBiometric.isAvailable().then(
                                (result) => {
                                    const isAvailable = result.isAvailable;
                                    if (isAvailable) {
                                        Alertas.Huella(idioma);
                                    } else {
                                        window.location = '/Home';
                                    }
                                },
                                (error) => {
                                    // No se pudo verificar la disponibilidad
                                    console.log(error);
                                }
                            );

                        }
                    }
                }
            }
        } catch (error) {
        }

    } else {
        Alertas.sinConexion(idioma);
    }
}


const DatosCliente = {
    obtener(user, pass, lengua, token) {
        return getDatosCliente(user, pass, lengua, token);
    },

};


export default DatosCliente;