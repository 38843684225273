import React, { useEffect } from 'react';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import TarjetaStripe from '../../components/Facturas/TarjetaStripe';



const token = JSON.parse(sessionStorage.getItem('toktarjeta'));
const stripePromise = loadStripe(token);


const PagoCorrecto = (props) => {
    /*const importe = props.data.cantidad;
    const factura = props.data.numfactura;*/

    return (
        <Elements stripe={stripePromise}>
            <TarjetaStripe datos={props.data} />
        </Elements>
    );
}

export default PagoCorrecto;