import api from "../servicios/api";
import Alertas from '../servicios/Alertas';
import DatosEmpresa from "./DatosEmpresa";


async function getRecuperarPassEmail(user, idioma) {

    if (user === '') {
        Alertas.userVacio(idioma);
    } else {
        let empresa = await DatosEmpresa.obtener(idioma);
        if (empresa == true) {
            let userData;
            await api.Clientes.recuperarPassEmail(user.trim().toUpperCase()).then(
                ({ data }) => (userData = data)
            ).catch(function (err) {
                console.log(err);
            });
            if (userData === `El usuario no existe.`) {
                Alertas.usuarioNoEx(idioma);
            } else if (userData.includes('No disponemos de ningún email válido para enviarte la contraseña de acceso')) {
                Alertas.sinEmail(idioma);
            } else if (userData === '¡Mensaje enviado!') {
                Alertas.emailEnviado(idioma);
            } else {
                Alertas.sinConexion(idioma);
            }
        } else {
            Alertas.sinConexion(idioma);
        }
    }
}
const RecuperarPassEmail = {
    obtener(user, idioma) {
        return getRecuperarPassEmail(user, idioma);
    },

};


export default RecuperarPassEmail;