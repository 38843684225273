import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import { withTranslation } from "react-i18next";
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonList } from '@ionic/react';

class AlertaTranslated extends React.Component {
    state = {
        loading: false,
        error: false,
        datos: '',
        sinDatos: false,
        agendas: '',
        contactos: ''


    };
    componentDidMount() {

    }

    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'Agenda'} />
        }

        return (
            <div className="container h-100 float-left" >
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">Crear Alerta</span>
                </div>
                <form onSubmit={this.props.onSubmit}>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Nombre</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="nombre"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Componente </IonLabel>
                        <select onChange={this.handleChange} className="form-input" name="tipoDocumento">
                            <option value='1'>Ofertas</option>
                            <option value='2'>Citas</option>
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Mostrar</IonLabel>
                        <select onChange={this.handleChange} className="selec-tipo-persona form-input" name="tipoPersona" >
                            <option value='1'>Siempre</option>
                            <option value='0'>Al cambiar</option>
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Visualizar</IonLabel>
                        <select onChange={this.handleChange} className="selec-tipo-persona form-input" name="tipoCuenta" >
                            <option value='6'>Solo usuario</option>
                            <option value='1'>Global</option>
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Fijar</IonLabel>
                        <select onChange={this.handleChange} className="selec-tipo-persona form-input" name="tipoCuenta" >
                            <option value='6'>No</option>
                            <option value='1'>Si</option>
                        </select>
                    </div>
                    <hr />


                    <div className="">
                        <button
                            type="button"
                            onClick={() => this.guardarClienteIsp()}
                            className="btn-inicio" >
                            {/*t('Login.entrar',)*/} Crear
                        </button>
                    </div>
                    {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
                </form>
            </div >
        );
    };

}
const Alerta = withTranslation('common')(AlertaTranslated)
export default Alerta;