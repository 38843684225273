import api from '../servicios/api';
import { NativeBiometric } from 'capacitor-native-biometric';

let userData;
async function getHuella(idioma) {

    let titulo = "Iniciar sesión con Huella digital";
    let pass = "Iniciar sesión con contraseña";
    if (idioma == "ca") {
        titulo = "Inicia sessió amb Empremta";
        pass = "Inicia sessió amb contrasenya";
    }
    if (idioma == "in") {
        titulo = "Login with Fingerprint";
        pass = "Login with password";
    }

    const cliente = JSON.parse(localStorage.getItem('user'));
    if (cliente != null) {
        await api.Clientes.registrarApp(cliente.cif).then(
            ({ data }) => (userData = data)
        );
    }


    NativeBiometric.isAvailable().then(
        (result) => {
            const isAvailable = result.isAvailable;
            const isFaceId = result.biometryType;
            if (isAvailable) {
                // Obtener los credenciales del cliente
                // console.log(isFaceId);
                NativeBiometric.getCredentials({
                    server: process.env.REACT_APP_APP_NAME,
                }).then((credentials) => {
                    NativeBiometric.verifyIdentity({
                        reason: "Iniciar sesión",
                        title: titulo,
                        subtitle: "",
                        description: "",
                        negativeButtonText: pass,
                    }).then(
                        () => {
                            // Verificación ok
                            // console.log("en la verificacion")
                            sessionStorage.setItem("token", 1);
                            window.location = "/Home";

                        },
                        (error) => {
                            console.log(error);
                        }
                    );
                });
            }
        },
        (error) => {
            // No se pudo verificar la disponibilidad
            console.log(error);
        }
    );

    //Borrar las credenciales del usuario
    /* NativeBiometric.deleteCredentials({
       server: "www.example.com",
     }).then();*/
}

const Huella = {
    obtener(idioma) {
        return getHuella(idioma);
    },

};


export default Huella;
