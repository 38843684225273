import Axios from "axios";
const BASE_URL = process.env.REACT_APP_API_DIRECT

const headers = {
    'Content-Type': 'application/json',
};

async function callApi(endpoint, llamada) {
    const url = BASE_URL + endpoint;

    if (llamada === "get") {
        const response = await Axios.get(url, {
            headers: headers,
            auth: {
                username: process.env.REACT_APP_USUARIO,
                password: process.env.REACT_APP_DIRECTP
            },
        });
        return response;
    } else {
        const response1 = await Axios.post(url, llamada, {
            headers: headers,
            auth: {
                username: process.env.REACT_APP_USUARIO,
                password: process.env.REACT_APP_DIRECTP
            },
        });
        return response1;
    }
}

const ApiDirect = {
    Clientes: {
        capturaPagoBono(idCobro, respuesta, pasarela, ddi, bono) {
            return callApi(`/capturarpagoBono?idCobro=${idCobro}&respuesta=${respuesta}&pasarela=${pasarela}&ddi=${ddi}&bono=${bono}`, "get", {
            });
        },
        capturaPago(cobro, respuesta, pasarela) {
            return callApi(`/capturar_pago?idCobro=${cobro}&respuesta=${respuesta}&pasarela=${pasarela}`, "get", {
            });
        },
        capturaPagoRecargas(idCobro, respuesta, pasarela, fechainicio, fechafin, meses, check) {
            return callApi(`/capturar_pago_recargas?idCobro=${idCobro}&respuesta=${respuesta}&pasarela=${pasarela}&fechainicio=${fechainicio}&fechafin=${fechafin}&meses=${meses}&check=${check}`, "get", {
            });
        },
    },
};

export default ApiDirect;