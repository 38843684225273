import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import Pregunta from '../../Imagenes/pregunta.png';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import Tabs from '../../components/TabsClientes/Tabs';
import { IonIcon } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';

let userData;
let resultC;

class CrearClienteTranslated extends React.Component {

    state = {
        alert: false,
        loading: true,
        datos: undefined,
        error: null,
        sinDatos: false,
        form: {
            nombre: null,
            apellidos: null,
            tipoDocumento: '1',
            dni: null,
            tipoPersona: '1',
            tipoCalle: '9',
            calle: null,
            numero: null,
            localidad: null,
            provincia: null,
            postal: null,
            telefono: null,
            email: null,
            movil: null,
            tipoCuenta: 6
        },
        alertOk: false,
        alertKo: false,
    };

    componentDidMount() {
        this.fetchData();
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null, datos: undefined });
        userData = '';
        try {
            await api.Clientes.listarCalles().then(
                ({ data }) => (userData = data)
            );
            if (Array.isArray(userData)) {
                this.setState({ loading: false, datos: userData, sinDatos: false });
            } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>tipoCalle</b>" == userData) {
                this.setState({ loading: false, sinDatos: true, datos: false });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };


    handleChange = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
        console.log(e.target.name + ':' + e.target.value)
    };


    guardarClienteIsp = async () => {
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.crearCliente(this.state.form).then(
                ({ data }) => (resultC = data)
            );
            if (resultC == 1) {
                this.setState({ loading: false });

                this.abrirAlertOk();
            } else {
                this.setState({ loading: false });
                this.abrirAlertKo();
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }

    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }



    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="tabsclientes">
                    <IonLabel className="titulo-pagina">Nuevo Cliente</IonLabel>
                </div>
                <form onSubmit={this.props.onSubmit}>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Nombre</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="nombre"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Apellidos</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="apellidos"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Tipo doc. </IonLabel>
                        <select onChange={this.handleChange} className="form-input" name="tipoDocumento" value={this.state.form.tipoDocumento}>
                            <option value='1'>NIF</option>
                            <option value='2'>CIF</option>
                            <option value='3'>NIE</option>
                            <option value='4'>Doc.Oficial de ident. expedido por el pais de residencia</option>
                            <option value='5'>Certificado de residencia fiscal</option>
                            <option value='6'>Otro documento probatorio</option>
                            <option value='7'>NIF/IVA(NIF operador intracomunitario)</option>
                            <option value='8'>Pasaporte</option>
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Id Fiscal</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="dni"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Tipo persona</IonLabel>
                        <select onChange={this.handleChange} className="selec-tipo-persona form-input" name="tipoPersona" value={this.state.form.tipoPersona}>
                            <option value='1'>Persona Física</option>
                            <option value='0'>Persona Jurídica</option>
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Cliente Potencial</IonLabel>
                        <select onChange={this.handleChange} className="selec-tipo-persona form-input" name="tipoCuenta" value={this.state.form.tipoCuenta}>
                            <option value='6'>Si</option>
                            <option value='1'>No</option>
                        </select>
                    </div>
                    <hr />
                    <span>Domicilio</span>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Tipo calle </IonLabel>
                        <select onChange={this.handleChange} className="form-input" name="tipoCalle" value={this.state.form.tipoCalle} >
                            {userData.map((dato) =>
                                <option key={dato.id} value={dato.id}>{dato.nombre}</option>
                            )}
                        </select>
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Calle</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="calle"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Número</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="numero"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Localidad</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="localidad"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Provincia</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="provincia"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Código postal</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="postal"
                        />
                    </div>
                    <hr />
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Telefóno</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="telefono"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Telefóno Móvil</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="movil"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Email</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="email"
                        />
                    </div>

                    <div className="">
                        <button
                            type="button"
                            onClick={() => this.guardarClienteIsp()}
                            className="btn-inicio" >
                            {/*t('Login.entrar',)*/} Guardar
                        </button>
                    </div>
                    {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
                </form>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('TicketNuevo.ticketOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('TicketNuevo.ticketKo',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

            </div>
        );

    }
};
const CrearCliente = withTranslation('common')(CrearClienteTranslated)
export default CrearCliente;
