import React from "react";
import "../../styles/GlobalComponents.css";
import { withTranslation } from "react-i18next";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import api from '../../components/servicios/api';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';

let resultC;
class OfertaTranslated extends React.Component {
    state = {
        loading: true,
        form: {
            id: this.props.oferta.id,
            estadoOferta: this.props.oferta.estado
        },
        alertOk: false,
        alertKo: false,
    };

    componentDidMount() {
        this.setState({
            form: {
                ...this.state.form,
                ['estadoOferta']: this.props.oferta.estado,

            },
            loading: false
        });
        /*if (this.props.cliente.estado === 'PENDIENTE') {
            // colorClass = 'class-naranja';
        } else if (this.props.cliente.estado === 'PRESENTADA') {
            // colorClass = 'class-grey';
        } else if (this.props.cliente.estado == 'CANCELADA') {
            colorClass = 'class-rojo';
        } else if (this.props.cliente.estado == 'ACEPTADA') {
            colorClass = 'class-verde';
        }*/

    }


    handleChange = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
        console.log(e.target.name + ':' + e.target.value)
    };


    guardarOferta = async () => {
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.cambiarEstadoOferta(this.state.form.id, this.state.form.estadoOferta).then(
                ({ data }) => (resultC = data)
            );
            if (resultC == 1) {
                this.setState({ loading: false });
                this.abrirAlertOk();
            } else {
                this.setState({ loading: false });
                this.abrirAlertKo();
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }

    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }




    render() {
        const { t } = this.props;


        if (this.state.loading) {
            return <PageLoading />;
        }


        return (
            <div className="componente-oferta">
                <div>
                    <span>Titulo : {this.props.oferta.titulo}</span>
                </div>
                <div>
                    <span>Descripción: {this.props.oferta.descripcion} </span>
                </div>
                <div className='ofertas-estado'>
                    <span>Estado:  </span>
                    <select className='tipoclienteFicha' onChange={this.handleChange} name='estadoOferta' value={this.state.form.estadoOferta}>
                        <option value='PENDIENTE'>PENDIENTE</option>
                        <option value='PRESENTADA'>PRESENTADA</option>
                        <option value='ACEPTADA'>ACEPTADA</option>
                        <option value='CANCELADA'>CANCELADA</option>
                    </select>
                    <FontAwesomeIcon icon="cloud-upload-alt" className="icon" onClick={() => this.guardarOferta()} />
                </div>
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('Clientes.alertaOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('Clientes.alertako',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

            </div>
        );
    }
}
const Oferta = withTranslation('common')(OfertaTranslated)
export default Oferta;