import React from 'react';
import { withTranslation } from "react-i18next";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import ok from '../../Imagenes/ok.png';

class PagoCorrectoTranslated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="error-pago-text">
                    <span> {t('PagoCorrecto.disculpa')}</span>
                </div>
                <div className="error-pago">
                    <img className="error-pago-img" src={ok} alt="Logotipo" />
                </div>
                <div className="error-pago-info">
                    {t('PagoCorrecto.info')}
                </div>
            </div>
        )

    }
}

const PagoCorrecto = withTranslation('common')(PagoCorrectoTranslated);
export default PagoCorrecto;

