import React, { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import PageLoading from "../PantallaCarga/PageLoading";
import Moment from 'react-moment';
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../PantallaCarga/PageError";
import { alarmOutline, callOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/react';
import { Contacts } from '@capacitor-community/contacts';
import { isPlatform } from '@ionic/react';
let espaciado = '';
class ConsumoListItemTranslated extends React.Component {

    state = {
        loading: false,
        error: null,
        color: false,
        nombre: ''
    };
    componentDidMount = async () => {
        this.setState({ nombre: '', loading: false });
        espaciado = ''
        let resultADO = 15;
        //resultADO = await this.obtenerContactos();
    }

    obtenerContactos = async () => {
        let prefijo = '+34' + this.props.consumo.destino;
        let parti = this.props.consumo.destino.slice(0, 3) + ' ' + this.props.consumo.destino.slice(3, 5) + ' ' + this.props.consumo.destino.slice(5, 7) + ' ' + this.props.consumo.destino.slice(7, 9);
        let BreakException = {};
        let telefonoRevisar = this.props.consumo.destino;
        if (isPlatform('android') && !isPlatform('mobileweb') || isPlatform('ios') && !isPlatform('mobileweb')) {
            let permiso = false;

            await Contacts.requestPermissions().then((res) => {
                if (res.granted == true || res.contacts == 'granted') {
                    permiso = true;
                }
            });
            if (permiso == true) {
                const projection = {
                    name: true,
                    phones: true,
                };

                const agendasCont = await Contacts.getContacts({
                    projection,
                });
                try {
                    agendasCont['contacts'].forEach(function (valor, indice, array) {
                        const number = valor.phones;
                        if (number != undefined) {
                            number.forEach(function (valor2, indice2, array) {
                                if (valor2.number == telefonoRevisar) {
                                    this.setState({ nombre: valor.name.display });
                                    throw BreakException;
                                }

                                if (valor2.number == prefijo) {
                                    this.setState({ nombre: valor.name.display });
                                    throw BreakException;
                                }

                                if (valor2.number == '+34 ' + espaciado) {
                                    this.setState({ nombre: valor.name.display });
                                    throw BreakException;
                                }

                                if (valor2.number == parti) {
                                    this.setState({ nombre: valor.name.display });
                                    throw BreakException;
                                }

                            });
                        }
                    });
                    this.setState({ loading: false })
                } catch (e) {
                    // if (e !== BreakException) throw e;
                    this.setState({ loading: false })
                }

            } else {
                this.setState({ nombre: '' })
            }
        }

    }

    render() {
        const { t } = this.props;
        if (this.state.error === true) {
            return <PageError />;
        }

        if (this.state.loading === true) {
            return <PageLoading />;
        } else {
            return (
                <div className="llamadas">
                    <div className="fecha-llamada">
                        <span><b>{t('DetalleConsumo.fecha',)}</b> </span>
                        <span><Moment format="DD/MM/YY " className="fecha">{this.props.consumo.fecha}</Moment></span >
                    </div>
                    <div className="fecha-llamada">
                        <span><b>{t('DetalleConsumo.hora',)}</b> </span>
                        <span>{this.props.consumo.hora}</span >
                    </div>
                    <div className="destino">
                        <span>{t('DetalleConsumo.telefono',)} </span>
                        <span > {this.state.nombre != '' ? this.state.nombre : this.props.consumo.destino}  </span>
                        <IonIcon className='icon-btn' icon={callOutline} />
                    </div>
                    <div className="destino">
                        <span>{t('DetalleConsumo.duracion',)}</span>
                        <span > {this.props.consumo.duracion}</span>
                        <IonIcon className='icon-btn' icon={alarmOutline} />
                    </div>
                    <div className="bloque_llamada">
                        {this.props.consumo.precio_real != '0' ?
                            <div className="precio_llamada">
                                <span>Precio </span>
                                <span > {this.props.consumo.precio_real}</span>
                                <span className='icon-btn' >€</span>
                            </div> : ''}
                    </div>
                </div>
            );
        }
    }
}
const ConsumoListItem = withTranslation('common')(ConsumoListItemTranslated)

function useSearchConsumos(consumos) {
    const [list, setList] = React.useState(consumos)

    useEffect(() => {
        const sortedList = [...list].sort(function (a, b) {
            var dateA = new Date(a.fecha).getTime();
            var dateB = new Date(b.fecha).getTime();
            return dateB > dateA ? 1 : -1;
        });
        setList(sortedList)
    }, [])

    const [query, setQuery] = React.useState("");
    const [fileteredConsumos, setfileteredConsumos] = React.useState(list);


    React.useMemo(() => {
        const result = list.filter((consumo) => {
            return `${consumo.fecha} ${consumo.destino}`
                .toLowerCase()
                .includes(query.toLowerCase());
        });

        setfileteredConsumos(result);
    }, [list, query]);

    return { query, setQuery, fileteredConsumos };
}




function ConsumoListTelefonoTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const consumos = props.consumos;

    const { query, setQuery, fileteredConsumos } = useSearchConsumos(consumos);

    if (fileteredConsumos.length === 0) {

        return (
            <div>
                <div className="div-buscar">
                    <FontAwesomeIcon icon="magnifying-glass" className="icon" />
                    <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <h5>{t('DetalleConsumo.nohay',)} </h5>
            </div>
        );

    } else {
        return (
            <div className="facturasList">
                <div className="div-buscar">
                    <FontAwesomeIcon icon="magnifying-glass" className="icon" />
                    <input
                        type="text"
                        className="input-buscar"
                        placeholder={t('Contratos.buscar',)}
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <ul className="list-unstyled">

                    {fileteredConsumos.map(consumo => {
                        return (
                            <li key={consumo.id}>
                                <ConsumoListItem consumo={consumo} />
                            </li>
                        );
                    })}
                </ul>
            </div>
        );

    }



}
const ConsumoListTelefono = withTranslation('common')(ConsumoListTelefonoTranslated)
export default ConsumoListTelefono;
