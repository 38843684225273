import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { Link } from 'react-router-dom';
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import imagenTicket from '../../Imagenes/imagenTicket.png';
import { Camera, CameraResultType } from '@capacitor/camera';
import Alertas from "../../components/servicios/Alertas";
let resultC;
let blob11 = '';

class NuevaOfertaTranslated extends React.Component {

    state = {
        loading: false,
        error: null,
        form: {
            cliente: this.props.location.state.codigo,
            descripcion: null,
            titulo: null,
            fechainicio: null,
            fechafin: null,
            precio: 0,
            photo: '',
        },
        alertOk: false,
        alertKo: false,
    };


    async takePicture() {
        const image = await Camera.getPhoto({
            quality: 90,
            allowEditing: false,
            resultType: CameraResultType.Uri
        });
        let imageUrl = image.webPath;
        blob11 = await fetch(image.webPath).then(r => r.blob()).catch(function (err) {
            //console.log(err);
        });
        this.setState({
            form: {
                ...this.state.form,
                ['photo']: imageUrl

            },
        })


        if (blob11.size > 4194304) {
            Alertas.demasiadoGrande();
            this.setState({
                form: {
                    ...this.state.form,
                    ['photo']: '',

                },
            })
        }

    }

    handleChange = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
        console.log(e.target.name + ':' + e.target.value)
    };


    guardarOferta = async () => {
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.crearOferta(this.state.form).then(
                ({ data }) => (resultC = data)
            );
            if (resultC == 1) {
                this.setState({ loading: false });
                this.abrirAlertOk();
            } else {
                this.setState({ loading: false });
                this.abrirAlertKo();
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }

    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }



    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="tabsclientes">
                    <IonLabel className="titulo-pagina">Nueva Oferta</IonLabel>
                </div>
                <form onSubmit={this.props.onSubmit}>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Titulo</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="titulo"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Descripción</IonLabel>
                        <textarea
                            onChange={this.handleChange}
                            className="form-input"
                            rows={5}
                            name="descripcion"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Cliente </IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="cliente"
                            readOnly
                            value={this.props.location.state.codigo}
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Fecha inicio</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="date"
                            name="fechainicio"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Fecha fin</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="date"
                            name="fechafin"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Precio</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="number"
                            min="1" step="any"
                            name="precio"
                        />
                    </div>
                    <hr />
                    <span>Imagen </span>
                    <div className="imagen">
                        <br></br>
                        {this.state.form.photo.length > 0 ?
                            <img className="sinImagen"
                                src={this.state.form.photo}
                                alt="sinImagen"
                                onClick={() => this.takePicture()}
                            /> : <img className="imagenTicket"
                                src={imagenTicket}
                                alt="sinImagen"
                                onClick={() => this.takePicture()}
                            />}
                    </div>


                    <div className="">
                        <button
                            type="button"
                            onClick={() => this.guardarOferta()}
                            className="btn-inicio" >
                            {/*t('Login.entrar',)*/} Crear
                        </button>
                    </div>
                    {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
                </form>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('Clientes.alertaOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('Clientes.alertako',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

            </div>
        );

    }
};
const NuevaOferta = withTranslation('common')(NuevaOfertaTranslated)
export default NuevaOferta;
