import React from 'react';
import api from '../../components/servicios/api';
import AceptarContacto from '../../components/Alertas/AceptarContacto';
import { withTranslation } from "react-i18next";

let userData;
let noticias = [];
class PubliFijaTranslated extends React.Component {

    state = {
        mostrar: false,
        alert: false,
        link: false,
    }



    async componentDidMount() {
        noticias = [];
        let portada = await this.fetchData();
        if (portada == true) {
            this.setState({ mostrar: true });
        }
    }

    fetchData = async () => {
        noticias = [];
        this.setState({ loading: true, error: null });

        try {
            await api.Clientes.noticiaHome().then(
                ({ data }) => (userData = data)
            );

            if (!Array.isArray(userData)) {
                return false;
            } else {
                for (let i = 0; i < userData.length; i++) {
                    noticias.push(userData[i]);
                    if (userData.contactar_cliente_app == 1) {
                        this.setState({ link: true });
                    } else {
                        this.setState({ link: false });
                    }
                }
                return true;
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    abrirAlert = () => {
        this.setState({ alert: true });
    }

    render() {
        const { t } = this.props;
        return (
            <div className={this.state.mostrar ? 'interesa-home' : 'hidden'}>
                {noticias.map(noticia => {
                    return (
                        <div key={noticia.id}>
                            <span className="noticias-descripcionhome" dangerouslySetInnerHTML={{ __html: noticia.descripcion }} />
                        </div>
                    );
                })}
                <div className={this.state.link ? 'interesa' : 'hidden'} onClick={() => this.abrirAlert()} >
                    <p>{t('TarifaBloque.interes')}</p>
                </div>
                {this.state.alert && <AceptarContacto dato="contacto" />}
            </div>
        )
    }
}

const PubliFija = withTranslation('common')(PubliFijaTranslated)
export default PubliFija;