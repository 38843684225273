import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import GraficoDonut from '../Home/GraficoDonut';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Link } from 'react-router-dom';
let userConsumo1 = [];
let indice;
let tarifas123 = [];
let resto;
let tarifas = [];
class ConsumoGenericoTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        ddi: '',
        loading: false,
        restante: '0000',
        total: '00',
        icono: 'datos',
        sinDatos: false,
        tarifas: '',
        minutos: 0,
        sms: 0,
    };

    componentDidMount() {
        this.setState({ array: '' });
        tarifas123 = []
        tarifas = []
        if (this.props.ddi != 'Seleccione un número') {
            this.fetchDataHome();
        }

    }

    fetchDataHome = async () => {
        let respuesta = [];
        this.setState({ loading: true, error: null, array: '' });
        try {
            await api.Clientes.consumoReal(this.props.ddi).then(
                ({ data }) => (userConsumo1 = data),
            );
            if (userConsumo1 != '' && userConsumo1 != 'Debe especificar los campos obligatorios ddi') {
                if (userConsumo1[0].titulo.includes('BONO') || userConsumo1[0].titulo.includes('TARIFA DOBLA GIGAS')) {
                    indice = 1;
                } else {
                    indice = 0;
                }


                if (typeof userConsumo1[indice].servicios && userConsumo1[indice].servicios != undefined && userConsumo1[indice].servicios != '') {

                    this.setState({ sms: userConsumo1[indice].servicios[0].sms_consumidos, minutos: userConsumo1[indice].servicios[0].minutos_consumidos });
                }

                this.setState({ loading: false, error: null, tarifas: userConsumo1 });
                this.establecerDatos('datos');
            } else {
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
            console.log(error)
        }
    }

    handleChange = async (e) => {
        this.setState({ idActual: e.target.value, verLeyenda: false });
        this.establecerDatos(e.target.value, this.state.icono);
    };

    establecerDatos = (icono) => {
        let datos;
        let consumido;
        let restante;
        let tarifas = this.state.tarifas;
        let servicios = [];

        this.setState({ tarifa: tarifas[indice].titulo });

        if (tarifas[indice].tarifa_plana == '' || tarifas[indice].tarifa_plana == null) {
            if (icono == 'datos') {
                datos = tarifas[indice].datos_app

                if (datos == '-1') {
                    datos = 'Ilim.';
                    restante = 'Ilimitados';
                } else {
                    if (datos == 0) {
                        datos = 0;
                        restante = 0;
                    } else {
                        consumido = tarifas[indice].datos_consumidos / 1024 / 1024 / 1024;
                        let consumo = datos - consumido.toFixed(2);
                        restante = consumo.toFixed(2)

                    }
                }
                this.setState({ medida: 'GB' });

            } else if (icono == 'sms') {
                datos = tarifas[indice].sms
                if (datos == '-1') {
                    datos = 'Ilim.';
                    restante = 'Ilimitados';
                } else {
                    consumido = tarifas[indice].sms_consumidos;
                    if (datos == 0) {
                        datos = 0;
                        restante = 0;
                    } else {
                        restante = datos - consumido;
                    }
                }
                this.setState({ medida: 'SMS' });

            } else if (icono == 'llamadas') {
                datos = tarifas[indice].minutos_app
                if (datos == '-1') {
                    datos = 'Ilim.';
                    restante = 'Ilimitados';
                } else {
                    consumido = tarifas[indice].minutos_consumidos / 60;
                    if (datos == 0) {
                        datos = 0;
                        restante = 0;
                    } else {
                        resto = datos - consumido;
                        restante = resto.toFixed(2);
                    }
                }
                this.setState({ medida: 'MIN' });
            }
        } else {
            servicios = tarifas[indice].servicios;
            for (let i = 0; i < servicios.length; i++) {
                if (icono == 'datos' && servicios[i].id_agrupacion_destino == '566') {
                    if (datos == '-1') {
                        datos = 'Ilim.';
                        restante = 'Ilimitados';
                    } else {
                        if (datos == 0) {
                            datos = 0;
                            restante = 0;
                        } else {
                            datos = servicios[i].datos / 1024;
                            consumido = servicios[i].datos_consumidos / 1024 / 1024 / 1024;
                            let consumo = datos - consumido.toFixed(2);
                            restante = consumo.toFixed(2)
                        }
                    }
                    this.setState({ medida: 'GB' });

                } else if (icono == 'sms' && servicios[i].id_agrupacion_destino == '565') {
                    datos = servicios[i].sms
                    if (datos == '-1') {
                        datos = 'Ilim.';
                        restante = 'Ilimitados';
                    } else {
                        consumido = servicios[i].sms_consumidos;
                        if (datos == 0) {
                            datos = 0;
                            restante = 0;
                        } else {
                            restante = datos - consumido;
                        }
                    }
                    this.setState({ medida: 'SMS' });

                } else if (icono == 'sms' && servicios[i].id_agrupacion_destino != '566' && servicios[i].id_agrupacion_destino != '565') {
                    datos = 0;
                    restante = 0;
                    this.setState({ medida: 'SMS' });

                } else if (icono == 'llamadas' && servicios[i].id_agrupacion_destino != '566' && servicios[i].id_agrupacion_destino != '565') {
                    datos = servicios[i].minutos;
                    if (datos == 0) {
                        datos = 'Ilim.';
                        restante = 'Ilimitados';
                    } else {
                        consumido = servicios[i].minutos_consumidos / 60;
                        if (datos == '-1') {
                            datos = 0;
                            restante = 0;
                        } else {
                            resto = datos - consumido;
                            restante = resto.toFixed(2);
                        }
                    }
                    this.setState({ medida: 'MIN' });
                }

            }


        }
        if (restante < 0) {
            restante = 0;
        }
        if (datos == undefined) {
            datos = 0;
        }

        if (restante == undefined) {
            restante = 0
        }
        this.setState({ total: datos, restante: restante });
    }


    datos = () => {
        this.establecerDatos('datos');
    }

    llamadas = () => {
        this.establecerDatos('llamadas');

    }

    sms = () => {
        this.establecerDatos('sms');
    }

    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }

        if (this.state.sinDatos === true) {
            return 'Sin datos para esta línea';
        }


        return (
            <div className="consumo-home-global">
                <GraficoDonut
                    restante={this.state.restante}
                    total={this.state.total}
                    medida={this.state.medida}
                    color={'#fcba0c'}
                    iconos={'grey'}
                    responsive={true} />
                <div className="contenedor-iconos" id="sms">
                    <button className="icono-linea-sms" onClick={() => this.sms()} >
                        <FontAwesomeIcon icon="comment-sms" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-llama" onClick={() => this.llamadas()} id="llamadas">
                        <FontAwesomeIcon icon="phone" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-gb" onClick={() => this.datos()} id="datos">
                        <div className={"iconos-consumo-home-dobles"} id="datos">
                            <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                            <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                        </div>
                    </button>

                </div>
                {process.env.REACT_APP_APP_NAME == 'TMOVIL' ?
                    <div className='resumen-consumo-rayas'>
                        <div className='resumen-consumo-rayas-min'>
                            <span className='rayita-min'>{t('Consumo.llama',)}</span>
                            <span className='rayita-min-cantidad'>{this.state.minutos} {t('Consumo.minutos',)}</span>
                        </div>
                        <div className='raya-abajo'>

                        </div>
                        <div className='resumen-consumo-rayas-sms'>
                            <span className='rayita-min'>SMS</span>
                            <span className='rayita-min-cantidad'>{this.state.sms} </span>
                        </div>

                    </div> : ''}
            </div>
        )


    }
}
const ConsumoGenerico = withTranslation('common')(ConsumoGenericoTranslated)
export default ConsumoGenerico;