import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import { withTranslation } from "react-i18next";
import Tabs from '../../components/Tabs/Tabs';
import Pageinfo from '../../components/PantallaCarga/Pageinfo';
import AgendasList from '../../components/Agenda/Agenda';
import Contactos from '../../components/Agenda/Contacts';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import md5 from "md5";
import { isPlatform } from '@ionic/react';
import { Keyboard } from '@capacitor/keyboard';

let userAgendas;
let userContactos;

class AgendasTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: '',
        sinDatos: false,
        agendas: '',
        contactos: ''


    };
    componentDidMount() {
        this.fetchData();
        //Contactos.con();
    }


    handleChange = async (e) => {


    };



    fetchData = async () => {
        this.setState({ loading: true, error: null });

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            if (cliente.esUsuario != '' && cliente.esUsuario != null && cliente.esUsuario !== undefined) {
                await api.Clientes.obtenerAgenda(cliente.nombre, cliente.usuario).then(
                    ({ data }) => (userAgendas = data)
                ).catch(function (err) {
                    console.log(err);
                });

                await api.Clientes.obtenerContactos(cliente.nombre, cliente.usuario).then(
                    ({ data }) => (userContactos = data)
                ).catch(function (err) {
                    console.log(err);
                });
            } else if (cliente.esUsuario == undefined) {
                await api.Clientes.obtenerAgendaClientes(cliente.usuario).then(
                    ({ data }) => (userAgendas = data)
                ).catch(function (err) {
                    console.log(err);
                });

                await api.Clientes.obtenerContactosContactos(cliente.usuario).then(
                    ({ data }) => (userContactos = data)
                ).catch(function (err) {
                    console.log(err);
                });
            }

            if (!Array.isArray(userAgendas)) {
                this.setState({ loading: false, sinDatos: true });
            } else {
                this.setState({ loading: false, agendas: userAgendas, contactos: userContactos });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };


    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.sinDatos) {
            return <Pageinfo datos={'Agenda'} />
        }

        return (
            <div className="container h-100 float-left" >
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span className="">{t('Home.agenda',)}</span>
                </div>
                <div className="agendas-tabs">
                    <Tabs>
                        {userAgendas.map(agenda => {
                            return (
                                <div label={agenda.nombre} key={agenda.id}>
                                    <div className="tarifasImagen">
                                        <AgendasList id={agenda.id} contactos={this.state.contactos} />
                                    </div>
                                </div>
                            )
                        })}
                    </Tabs>

                </div>
            </div >
        );
    };

}
const Agendas = withTranslation('common')(AgendasTranslated)
export default Agendas;