import api from "../servicios/api";
import Alertas from '../servicios/Alertas';

async function getDatosEmpresa(lengua) {
    let appConfig;
    let funcionalidades;
    try {


        await api.Clientes.configApp().then(
            ({ data }) => (appConfig = data)
        ).catch(function (err) {
            console.log(err);
        });

        if (!Array.isArray(appConfig)) {
            Alertas.sinConexion(lengua);
            return false;
        } else {
            funcionalidades = JSON.parse(appConfig[0].configuracion_funcionalidades);

            let appConf = {
                "acerca_de": appConfig[0].acerca_de,
                "asunto_ticket": appConfig[0].asunto_interesa_ticket,
                "agrupar_tarifas": appConfig[0].agrupar_tarifas_operador,
                "mostrar_ticket": appConfig[0].mostrar_ticket_interesa_tarifa,
                "mensaje": appConfig[0].mensaje_interesa_ticket,
                "config_inicio": appConfig[0].configuracion_inicio_app,
                "recuperar_clave": appConfig[0].recuperar_clave,
                "imagen_tarifa": appConfig[0].imagen_en_tarifas,
                "mostrar_puk": appConfig[0].mostrar_puk,
                "grafico_consumo": appConfig[0].grafico_consumo
            }

            localStorage.setItem("appconf", JSON.stringify(appConf));
            localStorage.setItem("funciones", JSON.stringify(funcionalidades));
            return true;
        }

    } catch (error) {

    }
}



const ConfigApp = {
    obtener(lengua) {
        return getDatosEmpresa(lengua);
    },

};


export default ConfigApp;