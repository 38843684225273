import React from 'react';
import { Switch, Redirect, Route } from 'react-router';
import { PublicRoute } from './helperRoutes';
import Login from '../pages/Index/Login';

import RecuperaContrasenia from '../pages/Index/RecuperaContrasenia';
import CambiarContraseniaIndex from '../pages/Index/CambiarContraseniaIndex';


/**
 * Rutas publicas
 */
const PublicRoutes = () => {
    return (
        <Switch>
            <PublicRoute exact path="/" component={Login} />
            <PublicRoute exact path="/RecuperaContrasenia" component={RecuperaContrasenia} />
            <PublicRoute exact path="/CambiarContraseniaIndex" component={CambiarContraseniaIndex} />
            < Route exact path="*" render={() => {
                return < Redirect to="/" />
            }} />


        </Switch>
    )

}

export default PublicRoutes;