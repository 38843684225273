import React, { useState, useEffect } from 'react';
import BanderaEs from '../../Imagenes/BanderaEs.jpg';
import BanderaCa from '../../Imagenes/BanderaCa.jpg';
import BanderaIn from '../../Imagenes/BanderaIn.jpg';
import i18next from "i18next";
import "../../styles/GlobalComponents.css";
import { isPlatform } from '@ionic/react';

function HeaderComponent() {
    const [ios, setIos] = useState(true);

    useEffect(() => {
        if (isPlatform('ios')) {
            setIos(false);
        } else {
            setIos(true);
        }
    }, [])

    return (
        <div className="container h-100">
            <div className="">
                <button type="button" className="banderas">
                    <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                        src={BanderaEs}
                        alt="Bandera-es" onClick={() => i18next.changeLanguage('es')} /></button>
                {/* <button type="button" className="banderas">
                    <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                        src={BanderaCa}
                    alt="BanderaCa" onClick={() => i18next.changeLanguage('ca')} /></button>*/}
                <button type="button" className="banderas">
                    <img className={ios ? 'Bandera-es' : 'Bandera-es-ios'}
                        src={BanderaIn}
                        alt="BanderaIn" onClick={() => i18next.changeLanguage('in')} /></button>
            </div>
        </div>
    );

};

export default HeaderComponent;