import api from "../servicios/api";
import Alertas from '../servicios/Alertas';


async function getRecuperarPass(user, idioma) {

    let userData;
    if (user === '') {
        Alertas.userVacio(idioma);
    } else {
        await api.Clientes.envioCliente(user.trim().toUpperCase(), 'recuperapass').then(
            ({ data }) => (userData = data)
        ).catch(function (err) {
            console.log(err);
        });
        if (Array.isArray(userData)) {

            let usuario = {
                "id": userData[0].id,
                "usuario": userData[0].clienteid,
                "codigo": userData[0].codigo,
                "cif": user.trim().toUpperCase(),
                "email": userData[0].email,
                "movil": userData[0].movil,
                "sms": userData[0].movil_sms
            };

            localStorage.setItem("user", JSON.stringify(usuario));
            localStorage.setItem("idioma", JSON.stringify(idioma));
            window.location = '/RecuperaContrasenia';
        } else if (userData == 'No se encuentra nada con el id ' + user.toUpperCase()) {
            Alertas.usuarioNoEx(idioma);
        } else if ("No se han encontrado datos para el modelo <b>clienteid</b>" == userData) {
            Alertas.usuarioNoEx(idioma);
        } else if ("pass incorrecta" == userData) {
            Alertas.sinConexion(idioma);
        }
        else {
            Alertas.despues(idioma);
        }
    }
}



const RecuperarPass = {
    obtener(user, idioma) {
        return getRecuperarPass(user, idioma);
    },

};


export default RecuperarPass;
