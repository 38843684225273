import React from 'react';
import "../../styles/GlobalPages.css";
import { withTranslation } from "react-i18next";
import ContraseniaComponent from '../../components/Clientes/ContraseniaComponent';
import NavbarVariable from '../../components/Navbar/NavbarVariable';


class ContraseniaTranslated extends React.Component {
    render() {
        const { t } = this.props;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Datos'} />
                </div>
                <ContraseniaComponent />
            </div>
        );
    }

};
const contrasenia = withTranslation('common')(ContraseniaTranslated)
export default contrasenia;