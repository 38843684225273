import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import api from "../../components/servicios/api";
import { Camera, CameraResultType } from '@capacitor/camera';
import imagenTicket from '../../Imagenes/imagenTicket.png';
import { withTranslation } from "react-i18next";
import PageLoading from '../../components/PantallaCarga/PageLoading';
import PageError from '../../components/PantallaCarga/PageError';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import PiePagina from '../../components/Navbar/PiePagina';
import Pregunta from '../../Imagenes/pregunta.png';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import Alertas from "../../components/servicios/Alertas";


let blob = '';
let id_tic = '';
class ComentarioNuevoTranslated extends React.Component {

  state = {
    loading: false,
    error: false,
    datos: undefined,
    form: {
      comentario: "",

    },
    photo: '',
    alertOk: false,
    alertKo: false,
    alert: false
  };

  async takePicture() {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      resultType: CameraResultType.Uri
    });
    let imageUrl = image.webPath;
    blob = await fetch(image.webPath).then(r => r.blob()).catch(function (err) {
      //console.log(err + "error procesando la imagen ");
    });
    this.setState({
      photo: imageUrl
    })

    if (blob.size > 4194304) {
      Alertas.demasiadoGrande();
      this.setState({
        photo: ''
      })
    }
  }

  abrirAlertOk = () => {
    this.setState({ alertOk: !this.state.alertOk });
  }
  abrirAlertKo = () => {
    this.setState({ alertKo: !this.state.alertKo });
  }
  abrirAlert = () => {
    this.setState({ alert: !this.state.alert });
  }

  cerrarSesion = () => {
    this.abrirAlert();
  };


  fetchData = async () => {
    this.setState({ loading: true, datos: null });
    //Primero creamos el comentario del ticket y luego enviamos la imagen con el id del comentario.
    try {
      const ticket = this.props.location.state.ticket;
      await api.Clientes.nuevoComentario(ticket, this.state.form.comentario).then(
        ({ data }) => (id_tic = data)
      );
      // console.log(id_tic);
      if (isNaN(parseInt(id_tic))) {
        this.setState({ loading: false, error: null });
        this.abrirAlertKo();
      } else {
        if (this.state.photo !== '') {
          await api.Clientes.nuevaImagen(id_tic, blob).then((result) => {
            if (result.data == true) {
              this.setState({ loading: false, datos: result.data });
              this.abrirAlertOk();
            } else {
              this.setState({ loading: false, datos: result.data });
              this.abrirAlertKo();
            }
          }).catch(function (err) {
            // console.log(err);
          });
        } else {
          this.setState({ loading: false, datos: id_tic });
          this.abrirAlertOk();
        }
      }
    } catch (error) {
      this.setState({ loading: false, error: true });
    }
  };

  handleChange = async (e) => {
    await this.setState({
      form: {
        ...this.state.form,
        [e.target.name]: e.target.value,
      },
    });
  };

  render() {

    const { t } = this.props;
    if (this.state.loading) {
      return <PageLoading />;
    }

    if (this.state.error) {
      return <PageError />;
    }
    return (
      <div className="container h-100">
        <div className="nav-navegacion">
          <NavbarVariable datos={'/Tickets'} />
        </div>
        <div className="titulo-pagina">
          <span className="">  {t('DetalleTicket.nuevo',)}</span>
        </div>
        <div className="Comentario-nuevo">
          <span className="descripcion-comentario">{t('DetalleTicket.comentario',)}</span>
          <textarea className="textinput" name="comentario" value={this.state.value1}
            onChange={this.handleChange} id="descripcion" cols="50" rows="6" />
        </div>
        <div className="imagen">
          {this.state.photo.length > 0 ?
            <img className="sinImagen"
              src={this.state.photo}
              alt="sinImagen"
              onClick={() => this.takePicture()}
            /> : <img className="imagenTicket"
              src={imagenTicket}
              alt="sinImagen"
              onClick={() => this.takePicture()}
            />}
        </div>
        <div className="botones-linea">
          <button onClick={() => this.fetchData()} className="btn-aceptar" >
            <FontAwesomeIcon icon="paper-plane" className="icon-btn" />
            <span className="" >{t('Button.enviar',)}</span>
          </button>
          <button onClick={() => this.cerrarSesion()} className="btn-rojo-s" >
            <FontAwesomeIcon icon="circle-xmark" className="icon-red" />
            <span className="cancelar" >{t('Button.cancelar',)}</span>
          </button>
        </div>
        <PiePagina />
        <IonAlert
          isOpen={this.state.alertOk}
          cssClass='correcto'
          header={t('TicketNuevo.ticketOk',)}
          message={`<img src="${Ok}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }]}
        />
        <IonAlert
          isOpen={this.state.alertKo}
          cssClass='error'
          header={t('TicketNuevo.ticketKo',)}
          message={`<img src="${Error}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }]}
        />
        <IonAlert
          isOpen={this.state.alert}
          cssClass='correcto info'
          header={t('DatosCliente.infoCerrar',)}
          message={`<img src="${Pregunta}" alt="error" className="errorimg">`}
          buttons={[{
            text: [`${t('Aceptar.cance',)}`],
            cssClass: 'info',
          },
          {
            text: [`${t('Aceptar.acep',)}`],
            handler: () => {
              window.location = '/Tickets';
            }
          }
          ]}
        />
      </div>
    );
  }
};
const ComentarioNuevo = withTranslation('common')(ComentarioNuevoTranslated)
export default ComentarioNuevo;