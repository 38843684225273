import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";

import "../../styles/GlobalComponents.css";

class PageErrorTranslated extends React.Component {
  render() {
    const { t } = this.props;
    return <div className="container h-100">
      <div className="nav-navegacion">
        <NavbarVariable datos={'/Home'} />
      </div>
      <div className="page-error">
        {t('Error.error')}
      </div>
    </div>;
  }
}
const PageError = withTranslation('common')(PageErrorTranslated)
export default PageError;
