import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { App } from '@capacitor/app';

const DeepLink = () => {
    let history = useHistory();
    useEffect(() => {
        App.addListener('appUrlOpen', (data) => {
            // Example url: https://beerswift.app/tabs/tab2
            // slug = /tabs/tab2
            console.log('imprimo data')
            console.log(data);
            console.log(data.url)

            const slug = data.url.split('://localhost').pop();
            if (slug) {
                console.log('entro al if')
                history.push(slug);
            } else {
                console.log('entro al else')
            }
            // If no match, do nothing - let regular routing
            // logic take over
        });
    }, []);

    return null;
};

export default DeepLink;
