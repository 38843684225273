import React from "react";
import "../../styles/GlobalComponents.css";
import { withTranslation } from "react-i18next";
import Util from "../../components/servicios/Util";


class DescuentoLineaTranslated extends React.Component {
    state = {
        loading: true,
        error: false,
        datos: [],
        sinDatos: false,
        factura: '',
        pendiente: '',
        modal: false,
    };
    render() {

        const { t } = this.props;

        return (

            <div className="container-component">
                <div className="dettalles-contrato">
                    <div className="descuento-detalle-div">
                        <p className="descontados"><b>Fecha :</b> {this.props.datos.fecha}</p>
                        <p className="descontados"><b>Concepto :</b> {this.props.datos.concepto}</p>
                        <p className="descontados"><b>Documento :</b> {this.props.datos.documento}</p>
                        <p className="descontados"><b>Total Dcto. : </b>{Util.aFloat(this.props.datos.total_descuento)}€</p>
                        <p className="descontados"><b>Descontado :</b> {Util.aFloat(this.props.datos.descontado)}€</p>
                        <p className="descontados"><b>Pendiente :</b> {Util.aFloat(this.props.datos.pendiente)}€</p>
                    </div>
                </div>
            </div >
        );
    }
}
const DescuentoLinea = withTranslation('common')(DescuentoLineaTranslated)
export default DescuentoLinea;