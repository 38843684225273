import React from 'react';
import { Link } from 'react-router-dom';
import "../../styles/GlobalComponents.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { withTranslation } from "react-i18next";
import { Navbar } from 'reactstrap';
import IonMenuBar from '../../components/Navbar/IonMenuBar';
import { IonButtons, IonMenuButton } from '@ionic/react';
import { isPlatform } from '@ionic/react';



class NavbarVariableTranslated extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            plataforma: true,

        }
    }
    componentDidMount() {
        if (isPlatform('ios')) {
            this.setState({ plataforma: false });
        } else {
            this.setState({ plataforma: true });
        }

    }
    render() {
        //const {children}= this.props
        const { t } = this.props;
        const info = this.props.datos;
        return (

            <div className="Navbar">
                <div className={this.state.plataforma ? 'Navbar-div' : 'Navbar-div-ios'}>

                </div>
                <Navbar fixed="top">
                    <Link className={this.state.plataforma ? 'Navbar-Logotipo' : 'Navbar-Logotipo-ios'} to={info}>
                        <FontAwesomeIcon icon="chevron-left" className="icon" />
                        {t('Nav.atras',)}
                    </Link>
                    <IonButtons slot="end" id="main-content" side="end">
                        <IonMenuButton slot="end" side="end" className={this.state.plataforma ? 'ion-botton-nav ionsmenuclass' : 'ion-botton-nav-ios ionsmenuclass'}></IonMenuButton>
                    </IonButtons>
                </Navbar>
                <IonMenuBar />
            </div>
        );
    }
}
const NavbarVariable = withTranslation('common')(NavbarVariableTranslated)
export default NavbarVariable;