import React from 'react';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import { withTranslation } from "react-i18next";
import ConsumoListTelefono from '../../components/Consumo/ConsumoListTelefono';
import ConsumoListMegas from '../../components/Consumo/ConsumoListMegas';
import Util from '../../components/servicios/Util';
import ConsumoListSms from '../../components/Consumo/ConsumoListSms';
import "../../styles/GlobalPages.css";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';


let value = '';
let documento = [];
class ConsumoDetalleTranslated extends React.Component {
    state = {
        loading: false,
        error: null,
        datos: this.props.location.state.detalle,
        color: true,

    };
    componentDidMount() {
        documento = [];
        this.fetchData();
        const tipo = this.props.location.state.tipo;

        if (tipo == 2) {
            this.setState({ color: false });
        }

    }




    fetchData = async () => {
        this.setState({ loading: true, error: null });
        const año = this.props.location.state.año;
        const telefono = this.props.location.state.telefono;
        const tipo = this.props.location.state.tipo;
        const mes = this.props.location.state.mes;

        try {
            await api.Clientes.consultaCdrDetalle(telefono, mes, año, tipo).then(
                ({ data }) => (value = data)
            );
            if (!Array.isArray(value)) {
                this.setState({ loading: false, error: true });
            } else {
                for (let i = 0; i < value.length; i++) {
                    documento.push(value[i]);
                }
                if (tipo == 1) {
                    for (let i = 0; i < documento.length; i++) {
                        if (documento[i].duracion) {
                            let tiempo = Util.seconds(documento[i].duracion);
                            documento[i].duracion = tiempo;
                        }
                    }
                }
                if (tipo == 3) {
                    for (let i = 0; i < documento.length; i++) {

                        if (documento[i].duracion) {
                            let tiempo = (documento[i].duracion / 1024 / 1024).toFixed(2);
                            documento[i].duracion = tiempo;
                        }
                    }
                }
                this.setState({ loading: false, datos: documento });
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };

    render() {
        const { t } = this.props;
        const tipo = this.props.location.state.tipo;

        if (this.state.loading === true) {
            return <PageLoading />;
        }

        if (this.state.error == true) {
            return <PageError />;
        }
        if (value === "No se han encontrado datos para el modelo <b>cdr</b>") {
            return (
                <div className="container h-100">
                    <div className="nav-navegacion">
                        <NavbarVariable datos={'/Consumo'} />
                    </div>
                    <div className="titulo-pagina">
                        <span>{t('DetalleConsumo.titulo',)}</span>
                    </div>
                    <div className="lista">
                        <span className="no-coment">{t('DetalleConsumo.sinDatos',)}</span>
                    </div>
                </div>
            );
        } else {
            if (tipo == '1' || tipo == '2' && documento != undefined) {
                return (
                    <div className="container h-100">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Consumo'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{t('DetalleConsumo.titulo',)}</span>
                        </div>
                        <ConsumoListTelefono consumos={documento} />
                        <PiePagina />
                    </div>
                );
            }
            if (tipo == '2') {
                return (
                    <div className="container h-100">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Consumo'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{t('DetalleConsumo.titulo',)}</span>
                        </div>
                        <div className="lista">
                            <ConsumoListSms consumos={documento} />
                            {this.state.loading && <MiniLoader />}
                        </div>
                        <PiePagina />
                    </div>
                );
            }
            if (tipo == '3') {
                return (
                    <div className="container h-100">
                        <div className="nav-navegacion">
                            <NavbarVariable datos={'/Consumo'} />
                        </div>
                        <div className="titulo-pagina">
                            <span className="">{t('DetalleConsumo.titulo',)}</span>
                        </div>
                        <div className="lista">
                            <ConsumoListMegas consumos={documento} />
                            {this.state.loading && <MiniLoader />}
                        </div>
                        <PiePagina />
                    </div>
                );
            }
        }
    }

}
const ConsumoDetalle = withTranslation('common')(ConsumoDetalleTranslated)
export default ConsumoDetalle;