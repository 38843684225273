import React from "react";
import "../../styles/GlobalComponents.css";
import Moment from 'react-moment';
import { withTranslation } from "react-i18next";

class TicketTranslated extends React.Component {
  render() {
    const { t } = this.props;
    return (
      <div className="container-component">
        <div className="num-ticket">
          <span><strong>{t('Ticket.numero',)}</strong> </span>
          {this.props.id}
        </div>
        <div className="fecha-ticket">
          <span><strong>{t('Ticket.fecha',)}</strong>   </span>
          <Moment format="DD/MM/YY  HH:MM" className="fecha">{this.props.fecha}</Moment>
        </div>
        <div className="descripcion-ticket">
          <span><strong>{t('Ticket.descripcion',)}</strong> </span>
          <span className="descripcion-ticket-span" dangerouslySetInnerHTML={{ __html: this.props.asuntoamplia }} />
        </div>
      </div>

    );
  }
}
const Ticket = withTranslation('common')(TicketTranslated)
export default Ticket;