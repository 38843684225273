import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import { withTranslation } from "react-i18next";
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import GraficoDonut from '../Home/GraficoDonut';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { IonAlert } from '@ionic/react';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
import { Link } from 'react-router-dom';

let userConsumo1 = [];
let estRo = '';
class ConsumoRealAireTranslated extends React.Component {
    constructor(props) {
        super(props);
    }
    state = {
        ddi: '',
        loading: false,
        restante: '0000',
        total: '00',
        icono: 'datos',
        sinDatos: false,
        tarifas: '',
        minutos: 0,
        sms: 0,
        tarifaContratada: '',
        roamingDatos: 0,
        roamingLlama: 0,
        estadoRoamingData: false,
        estadoRoamingLLama: false,
        estadoRoaming: false,
        modal: false,
        modal1: false,
        alertOk: false,
        alertKo: false,
        mostrarBonos: false,
        modalBono: false
    };

    componentDidMount() {
        this.setState({ telefono: this.props.ddi });
        this.ConsumoRealFetch();
        this.consultaRoaming();
    }

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
    }
    abrirModal1 = () => {
        this.setState({ modal: !this.state.modal1 });
    }

    abrirModalBono = () => {
        this.setState({ modalBono: !this.state.modalBono });
    }

    consultaRoaming = async () => {
        estRo = '';
        if (this.props.ddi != '') {
            try {
                await api.Clientes.consultarRoaming(this.props.ddi).then(
                    ({ data }) => (estRo = data),
                );

                if (typeof (estRo['datos']) && typeof (estRo['llama'])) {
                    if (estRo['datos'] == 1) {
                        this.setState({ estadoRoamingData: true });
                    } else {
                        this.setState({ estadoRoamingData: false });
                    }
                    if (estRo['llama'] == 1) {
                        this.setState({ estadoRoamingLLama: true });
                    } else {
                        this.setState({ estadoRoamingLLama: false });
                    }
                    if (estRo['llama'] == 1 || estRo['datos'] == 1) {
                        this.setState({ estadoRoaming: true });
                    }
                    this.setState({ mostrarRoaming: true });
                } else {
                    this.setState({ mostrarRoaming: false });
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    ConsumoRealFetch = async () => {
        userConsumo1 = [];
        try {
            if (this.props.ddi != '') {
                this.setState({ loading: true, error: null });
                try {
                    await api.Clientes.consumoReal(this.props.ddi).then(
                        ({ data }) => (userConsumo1 = data),
                    );
                    if (userConsumo1 != '') {
                        this.setState({ loading: false, error: null, tarifas: userConsumo1 });
                        let tarifas = this.state.tarifas;
                        if (typeof userConsumo1['sms']['consumido'] === undefined) {
                            this.setState({ sms: 0, minutos: userConsumo1['voz']['consumido'].toFixed(0) });
                        } else {
                            this.setState({ sms: userConsumo1['sms']['consumido'], minutos: userConsumo1['voz']['consumido'].toFixed(0) });
                        }
                        this.establecerDatos('datos');
                    } else {
                        this.setState({ loading: false, error: null, sinDatos: true });
                    }
                } catch (error) {

                }
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    establecerDatos = (icono) => {
        let datos;
        let consumido;
        let restante;
        let tarifas = this.state.tarifas;


        if (icono == 'datos') {

            if (typeof tarifas['tarifa']['datos_app'] && tarifas['tarifa']['datos_app'] != undefined && tarifas['tarifa']['datos_app'] != '' && tarifas['tarifa']['datos_app'] != null) {

                datos = tarifas['tarifa']['datos_app'];
            } else {

                datos = tarifas['datos']['contratado'];
            }

            consumido = tarifas['datos']['consumido'];
            let consumo = datos - consumido.toFixed(2);
            restante = consumo.toFixed(2)

            this.setState({ medida: 'GB' });
        } else if (icono == 'sms') {
            if (typeof tarifas['tarifa']['sms'] && tarifas['tarifa']['sms'] != undefined && tarifas['tarifa']['sms'] != '' && tarifas['tarifa']['sms'] != null) {

                datos = tarifas['tarifa']['sms'];
            } else {
                if (typeof tarifas['sms']['consumido'] === undefined) {
                    datos = 0;
                } else {
                    datos = tarifas['sms']['consumido'];
                }
            }


            consumido = tarifas['sms']['consumido'];
            let consumo = datos - consumido;
            restante = consumo;
            this.setState({ medida: 'SMS' });
        } else if (icono == 'llamadas') {
            if (typeof tarifas['tarifa']['minutos_app'] && tarifas['tarifa']['minutos_app'] != undefined && tarifas['tarifa']['minutos_app'] && tarifas['tarifa']['minutos_app'] != null) {

                datos = tarifas['tarifa']['minutos_app'];
            } else {

                datos = tarifas['datos']['contratado'];
            }
            consumido = tarifas.voz.consumido;
            if (datos == '-1') {
                restante = 'Ilim.'
                datos = 'Ilim.'
            } else {
                let consumo = datos - consumido;
                restante = consumo.toFixed(2);
            }

            this.setState({ medida: 'MIN' });
        }

        if (restante < 0) {
            restante = 0;
        }

        if (restante == 0 || restante < 1) {
            this.setState({ modalBono: true })
        }

        if (datos == undefined) {
            datos = 0;
        }

        if (restante == undefined) {
            restante = 0
        }
        if (tarifas['tarifa'] != 0) {
            this.setState({ total: datos, restante: restante, tarifaContratada: tarifas['tarifa']['nombre_comercial_app'] });
        } else {
            this.setState({ total: datos, restante: restante, tarifaContratada: '' });
        }


    }

    datos = () => {
        this.establecerDatos('datos');
    }

    llamadas = () => {
        this.establecerDatos('llamadas');

    }

    sms = () => {
        this.establecerDatos('sms');
    }

    mostrarRoamingAire = () => {
        this.setState({ mostrarRoamingAire: true })
    }

    activarRoaming = async (opcion, accion) => {
        console.log('first')
        let activa = '';
        if (opcion == 'datos') {
            var datosR = {
                "datos": accion
            }
        } else {
            var datosR = {
                "llama": accion
            }
        }

        if (this.props.ddi != '') {
            this.setState({ loading: true, error: null });
            try {
                await api.Clientes.activarRoaming(this.props.ddi, JSON.stringify(datosR)).then(
                    ({ data }) => (activa = data),
                );
                this.setState({ loading: false, error: null });
                if (activa == 'OK') {
                    this.abrirAlertOk();
                } else {
                    this.abrirAlertKo();
                }
            } catch (error) {
                console.log(error)
                this.setState({ loading: false, error: null, sinDatos: true });
            }
        }
    }

    render() {
        const { t } = this.props;

        if (this.state.loading === true) {
            return <PageLoading />;
        }
        if (this.state.error) {
            return <PageError />;
        }


        return (
            <div className="consumo-home-global">
                {this.state.tarifaContratada != '' ? <div className='nombretarifa'><span><b>{t('Consumo.contratada',)}</b></span><p>{this.state.tarifaContratada}</p></div> : ''}
                <GraficoDonut
                    restante={this.state.restante}
                    total={this.state.total}
                    medida={this.state.medida}
                    color={'#fcba0c'}
                    iconos={'grey'}
                    responsive={true} />
                <div className="contenedor-iconos" id="sms">
                    <button className="icono-linea-sms" onClick={() => this.sms()} >
                        <FontAwesomeIcon icon="comment-sms" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-llama" onClick={() => this.llamadas()} id="llamadas">
                        <FontAwesomeIcon icon="phone" className={"iconos-consumo-home"} />
                    </button>
                    <button className="icono-linea-gb" onClick={() => this.datos()} id="datos">
                        <div className={"iconos-consumo-home-dobles"} id="datos">
                            <FontAwesomeIcon icon="arrow-up" className={"iconos-gris"} id='datos' />
                            <FontAwesomeIcon icon="arrow-down" className={"iconos-gris"} id="datos" />
                        </div>
                    </button>

                </div>
                {process.env.REACT_APP_APP_NAME == 'TMOVIL' ? <div className='resumen-consumo-rayas'>
                    <div className='resumen-consumo-rayas-min'>
                        <span className='rayita-min'>{t('Consumo.llama',)}</span>
                        <span className='rayita-min-cantidad'>{this.state.minutos} {t('Consumo.minutos',)}</span>
                    </div>
                    <div className='raya-abajo'>

                    </div>
                    <div className='resumen-consumo-rayas-sms'>
                        <span className='rayita-min'>SMS</span>
                        <span className='rayita-min-cantidad'>{this.state.sms} </span>
                    </div>
                    {this.state.mostrarRoaming ?
                        <div className='activar-roaming'>
                            <span className='rayita-min'>{t('ConsumoReal.roaming',)}</span>
                            {this.state.estadoRoaming ? <div className='float-roaming'>
                                <div className="switch-button-roaming">
                                    <input
                                        type="checkbox"
                                        name="switchLabel"
                                        id="switchLabel"
                                        onClick={() => this.mostrarRoamingAire()}
                                        className="switch-button__checkbox-roaming" />
                                    <label htmlFor="switchLabel" className="switch-button__label-roaming" onChange={this.handleChange} />
                                </div>
                            </div>
                                : <div className='float-roaming'>
                                    <div className="switch-button-roaming-apa">
                                        <input
                                            type="checkbox"
                                            name="switchLabel"
                                            id="switchLabel"
                                            onClick={() => this.mostrarRoamingAire()}
                                            className="switch-button__checkbox-roaming-apa" />
                                        <label htmlFor="switchLabel" className="switch-button__label-roaming-apa" onChange={this.handleChange} />
                                    </div>
                                </div>
                            }

                        </div>
                        : ''}
                    {this.state.mostrarRoamingAire ?
                        <div className='activar-roaming'>
                            <span className='rayita-min'>Datos</span>
                            {this.state.estadoRoamingData ? <div className='float-roaming'>
                                <div className="switch-button-roaming1">
                                    <input
                                        type="checkbox"
                                        name="switchLabelLL"
                                        id="switchLabelLL"
                                        onClick={() => this.activarRoaming('datos', 2)}
                                        className="switch-button__checkbox-roaming1" />
                                    <label htmlFor="switchLabelLL" className="switch-button__label-roaming1" onChange={this.handleChange} />
                                </div>
                            </div>
                                : <div className='float-roaming'>
                                    <div className="switch-button-roaming-apa1">
                                        <input
                                            type="checkbox"
                                            name="switchLabelLL"
                                            id="switchLabelLL"
                                            onClick={() => this.abrirModal()}
                                            className="switch-button__checkbox-roaming-apa1" />
                                        <label htmlFor="switchLabelLL" className="switch-button__label-roaming-apa1" onChange={this.handleChange} />
                                    </div>
                                </div>
                            }

                        </div>
                        : ''}{this.state.mostrarRoamingAire ?
                            <div className='activar-roaming'>
                                <span className='rayita-min'>llamadas</span>
                                {this.state.estadoRoamingLLama ? <div className='float-roaming'>
                                    <div className="switch-button-roaming">
                                        <input
                                            type="checkbox"
                                            name="switchLabelDatos"
                                            id="switchLabelDatos"
                                            onClick={() => this.activarRoaming('llama', 2)}
                                            className="switch-button__checkbox-roaming" />
                                        <label htmlFor="switchLabelDatos" className="switch-button__label-roaming" onChange={this.handleChange} />
                                    </div>
                                </div>
                                    : <div className='float-roaming'>
                                        <div className="switch-button-roaming-apa">
                                            <input
                                                type="checkbox"
                                                name="switchLabelDatos"
                                                id="switchLabelDatos"
                                                onClick={() => this.abrirModal1()}
                                                className="switch-button__checkbox-roaming-apa" />
                                            <label htmlFor="switchLabelDatos" className="switch-button__label-roaming-apa" onChange={this.handleChange} />
                                        </div>
                                    </div>
                                }

                            </div>
                            : ''}

                </div> : ''}
                <Modal isOpen={this.state.modal} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('ConsumoReal.info5',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('ConsumoReal.info1',)}</p>
                        <p>{t('ConsumoReal.info2',)}</p>
                        <p>{t('ConsumoReal.info3',)}</p>
                        <p>{t('ConsumoReal.info4',)}</p>

                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming('llama', 1)}>
                            <span>{t('ConsumoReal.activar',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.modal1} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('ConsumoReal.info5',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModal}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('ConsumoReal.info1',)}</p>
                        <p>{t('ConsumoReal.info2',)}</p>
                        <p>{t('ConsumoReal.info3',)}</p>
                        <p>{t('ConsumoReal.info4',)}</p>

                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <button className="btn-rojo-xl-tarifas" onClick={() => this.activarRoaming('datos', 1)}>
                            <span>{t('ConsumoReal.activar',)}</span>
                        </button>
                    </ModalFooter>
                </Modal>
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('ConsumoReal.roamingOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('ConsumoReal.roamingko',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                        handler: () => {
                            window.location = '/Home';
                        }
                    }]}
                />
                <Modal isOpen={this.state.modalBono} className="roaming-modal">
                    <ModalHeader className="modal-header-roaming">
                        <span style={{
                            fontWeight: 800,
                        }}>{t('Contratacion.quieresBono',)}</span>
                        <div className="modal-cerrar-tarifa">
                            <button className="modal-cerrar-tarifa" onClick={this.abrirModalBono}>
                                <FontAwesomeIcon icon="xmark" className="icon-black-tarifas" />
                            </button>
                        </div>
                        {this.props.nombre}
                    </ModalHeader>
                    <ModalBody className="modal-body-roaming">
                        <p>{t('Contratacion.info',)}</p>
                    </ModalBody>
                    <ModalFooter className='modal-footer-roaming'>
                        <Link to={{
                            pathname: `/Contratacion/`
                        }} className="btn-bonos-xl">

                            <FontAwesomeIcon icon="cart-shopping" className='icon-white' />
                            <span className='titulo-btn-modal-bono'>{t('Contratacion.contratar',)}</span>
                        </Link>
                    </ModalFooter>
                </Modal>
            </div>
        )


    }
}

const ConsumoRealAire = withTranslation('common')(ConsumoRealAireTranslated)
export default ConsumoRealAire;