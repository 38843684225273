import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { IonItem, IonLabel, IonList } from '@ionic/react';
import { withTranslation } from "react-i18next";
import i18next from "i18next";
import { IonAlert } from '@ionic/react';
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageError from '../../components/PantallaCarga/PageError';
import PageLoading from '../../components/PantallaCarga/PageLoading';
import Error from '../../Imagenes/error.png';
import Ok from '../../Imagenes/ok.png';
let resultC;


class NuevaCitaTranslated extends React.Component {

    state = {
        loading: false,
        error: null,
        photo: '',
        form: {
            titulo: '',
            descripcion: '',
            cliente: this.props.location.state.codigo,
            fecha: '',
            hora: '',
            tipo: 'videollamada',
        },
        alertOk: false,
        alertKo: false,
    };

    handleChange = async (e) => {
        this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,

            },
        });
        console.log(e.target.name + ':' + e.target.value)
    };


    guardarCita = async () => {
        this.setState({ loading: true, error: null });
        try {
            await api.Clientes.crearCita(this.state.form).then(
                ({ data }) => (resultC = data)
            );
            if (resultC == 1) {
                this.setState({ loading: false });
                this.abrirAlertOk();
            } else {
                this.setState({ loading: false });
                this.abrirAlertKo();
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }

    };

    abrirAlertOk = () => {
        this.setState({ alertOk: !this.state.alertOk });
    }
    abrirAlertKo = () => {
        this.setState({ alertKo: !this.state.alertKo });
    }



    render() {
        const { t } = this.props;

        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="tabsclientes">
                    <IonLabel className="titulo-pagina">Nueva Cita</IonLabel>
                </div>
                <form onSubmit={this.props.onSubmit}>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Titulo</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="titulo"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Descripción</IonLabel>
                        <textarea
                            onChange={this.handleChange}
                            className="form-input"
                            rows={8}
                            name="descripcion"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Cliente </IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="text"
                            name="cliente"
                            readOnly
                            value={this.props.location.state.codigo}
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Fecha</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="date"
                            name="fecha"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Hora</IonLabel>
                        <input
                            onChange={this.handleChange}
                            className="form-input"
                            type="time"
                            name="hora"
                        />
                    </div>
                    <div className='form-group-crear-cliente'>
                        <IonLabel className="label-cliente">{/*t('Login.usuario',)*/}Tipo</IonLabel>
                        <select onChange={this.handleChange} className="form-input" name="tipo">
                            <option value='videollamada'>Videollamada</option>
                            <option value='visita'>Visita comercial</option>
                            <option value='llamada'>Llamada</option>
                        </select>
                    </div>

                    <div className="">
                        <button
                            type="button"
                            onClick={() => this.guardarCita()}
                            className="btn-inicio" >
                            {/*t('Login.entrar',)*/} Crear
                        </button>
                    </div>
                    {this.props.error && (<p className="text-danger">{this.props.error.message}</p>)}
                </form>
                <PiePagina />
                <IonAlert
                    isOpen={this.state.alertOk}
                    cssClass='correcto'
                    header={t('Clientes.alertaOk',)}
                    message={`<img src="${Ok}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />
                <IonAlert
                    isOpen={this.state.alertKo}
                    cssClass='error'
                    header={t('Clientes.alertako',)}
                    message={`<img src="${Error}" alt="error" className="errorimg">`}
                    buttons={[{
                        text: [`${t('Aceptar.acep',)}`],
                    }]}
                />

            </div>
        );

    }
};
const NuevaCita = withTranslation('common')(NuevaCitaTranslated)
export default NuevaCita;
