import React, { useEffect } from "react";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "../../styles/GlobalComponents.css";
import classNames from 'classnames/bind';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import { useTranslation, withTranslation } from "react-i18next";
import PageError from "../../components/PantallaCarga/PageError";
import { Link } from 'react-router-dom';

let colorClass;
class OfertasListItemTranslated extends React.Component {

    state = {
        loading: true,
        error: false,
    };



    componentDidMount() {
        /*  if (this.props.cliente.estado === 'PENDIENTE') {
              // colorClass = 'class-naranja';
          } else if (this.props.cliente.estado === 'PRESENTADA') {
              // colorClass = 'class-grey';
          } else if (this.props.cliente.estado == 'CANCELADA') {
              colorClass = 'class-rojo';
          } else if (this.props.cliente.estado == 'ACEPTADA') {
              colorClass = 'class-verde';
          }*/
        this.setState({
            loading: false
        })
    }


    render() {
        const { t } = this.props;
        if (this.state.error) {
            return <PageError />;
        }
        if (this.state.loading === true) {
            return <PageLoading />;
        } else {
            return (
                <div className="listado-clientes">
                    <Link
                        to={{
                            pathname: `/Cliente/`,
                            state: {
                                codigo: `${this.props.cliente.codigo}`,
                            }
                        }}
                    >
                        <div className='facClass' >
                            <div className="num-factura">
                                <FontAwesomeIcon icon="file" className="icon-listado-clientes" />
                                <span className='btnClass'> Cliente: </span>
                                <span>{/*t('Facturas.factura',)*/} {this.props.cliente.cliente}</span>

                            </div>
                            <div className="fecha-factura">
                                <span>{/*t('DetalleConsumo.fecha',)*/} Titulo:  </span>
                                {this.props.cliente.titulo}
                            </div>
                            <div className="fecha-factura">
                                <span>{/*t('DetalleConsumo.fecha',)*/} Descripción : </span>
                                {this.props.cliente.titulo}
                            </div>
                            <div className="estado-oferta">
                                <span>{/*t('DetalleConsumo.fecha',)*/} Estado : </span>
                                <span className={colorClass}>{this.props.cliente.estado}</span>
                            </div>
                            {this.state.loading && <MiniLoader />}
                        </div>
                    </Link>

                </div>
            );
        }
    }
}
const OfertasListItem = withTranslation('common')(OfertasListItemTranslated)

function useSearchClientes(clientes) {
    const [list, setList] = React.useState(clientes);


    useEffect(() => {
        const sortedList = [...list].sort(function (a, b) {
            var dateA = new Date(a.fecha).getTime();
            var dateB = new Date(b.fecha).getTime();
            return dateB > dateA ? 1 : -1;
        });
        setList(sortedList)
    }, [])
    const [query, setQuery] = React.useState("");
    const [fileteredClientes, setfileteredClientes] = React.useState(list);


    React.useMemo(() => {
        const result = list.filter((clientes) => {
            return `${clientes.factura} ${clientes.total}`
                .toLowerCase()
                .includes(query.toLowerCase());
        });

        setfileteredClientes(result);
    }, [list, query]);

    return { query, setQuery, fileteredClientes };
}




function OfertasListTranslated(props) {
    const [t, i18n] = useTranslation('common');
    const clientes = props.clientes;
    const { query, setQuery, fileteredClientes } = useSearchClientes(clientes);

    if (fileteredClientes.length === 0) {

        return (
            <div>
                <div className="div-buscar">
                    <FontAwesomeIcon icon="magnifying-glass" className="icon" />
                    <input
                        type="text"
                        className="form-control"
                        value={query}
                        onChange={e => {
                            setQuery(e.target.value);
                        }}
                    />
                </div>
                <h5>{t('Facturas.info',)} </h5>
            </div>
        );
    }

    return (
        <div className="facturasList">
            <div className="div-buscar">
                <FontAwesomeIcon icon="magnifying-glass" className="icon" />
                <input
                    type="text"
                    className="input-buscar"
                    placeholder={t('Contratos.buscar',)}
                    value={query}
                    onChange={e => {
                        setQuery(e.target.value);
                    }}
                />
            </div>
            <ul className="list-unstyled">

                {fileteredClientes.map(cliente => {
                    return (
                        <li key={cliente.id}>
                            <OfertasListItem cliente={cliente} />
                        </li>
                    );
                })}
            </ul>
        </div>
    );

}
const OfertasList = withTranslation('common')(OfertasListTranslated)
export default OfertasList;
