import React from 'react';
import Util from '../servicios/Util';
import { Modal, ModalBody } from 'reactstrap';
import api from '../servicios/api';

let userData;
let noticias = [];

class NoticiaModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            time: {}, seconds: 0,
            cambio: '',
            mostrar: null
        };
        this.timer = 0;
        this.startTimer = this.startTimer.bind(this);
        this.countDown = this.countDown.bind(this);

    }


    async componentDidMount() {
        const mostrar = JSON.parse(localStorage.getItem("modal"));

        userData = '';
        noticias = [];
        let respuesta = await this.fetchData();
        //console.log(this.state.seconds);
        if (!mostrar == true) {
            if (respuesta == true) {
                if (this.state.seconds > 0) {
                    localStorage.setItem("modal", JSON.stringify(true));
                    this.abrirModal();
                    setTimeout(() => this.abrirModal(), this.state.seconds * 1000);
                    let timeLeftVar = Util.segundos(this.state.seconds);
                    this.setState({ time: timeLeftVar });
                    this.startTimer();
                } else {
                    localStorage.setItem("modal", JSON.stringify(true));
                    this.abrirModal();
                }
            }
        } else {

        }

    }
    startTimer() {
        if (this.timer == 0 && this.state.seconds > 0) {
            this.timer = setInterval(this.countDown, 1000);
        }
    }

    countDown() {
        // Remove one second, set state so a re-render happens.
        let seconds = this.state.seconds - 1;
        this.setState({
            time: Util.segundos(seconds),
            seconds: seconds,
        });
        if (seconds == 0) {
            clearInterval(this.timer);
        }
    }


    componentWillUnmount() {
        clearInterval(this.timer);
    }

    abrirModal = () => {
        this.setState({ modal: !this.state.modal });
        sessionStorage.setItem("modal", JSON.stringify('true'));

    }

    cerrarModal = () => {
        this.setState({ modal: false });
        clearInterval(this.timer);
        this.setState({ time: '' });


    }

    fetchData = async () => {
        this.setState({ loading: true, error: null });
        noticias = [];

        try {
            await api.Clientes.noticiaDestacada().then(
                ({ data }) => (userData = data)
            );
            //console.log(userData);
            if (!Array.isArray(userData)) {
                return false;
            } else {
                for (let i = 0; i < userData.length; i++) {
                    noticias.push(userData[i]);
                }
                for (let i = 0; i < noticias.length; i++) {
                    if (noticias[i].texto_boton_app == '' && noticias[i].tiempo_mostrar_app == null) {
                        noticias[i].texto_boton_app = 'Saltar';
                        this.setState({ mostrar: true });
                    }
                    if (noticias[i].tiempo_mostrar_app != null && noticias[i].texto_boton_app != '') {
                        this.setState({ seconds: noticias[i].tiempo_mostrar_app });
                        noticias[i].texto_boton_app = '';
                        this.setState({ mostrar: false });
                    }
                    if (noticias[i].tiempo_mostrar_app == null && noticias[i].texto_boton_app != '') {
                        this.setState({ mostrar: true });
                    }
                    if (noticias[i].tiempo_mostrar_app != null && noticias[i].texto_boton_app == '') {
                        this.setState({ mostrar: false });
                        this.setState({ seconds: noticias[i].tiempo_mostrar_app });
                    }
                }
                this.setState({ loading: false, datos: userData });
                return true;
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };


    render() {
        return (
            <div>
                <Modal isOpen={this.state.modal} >
                    <ModalBody className="modalbody">
                        {noticias.map(noticia => {
                            return (
                                <div className='anuncio' key={noticia.id}>
                                    <button className={this.state.mostrar ? 'modalNoticiaCerrar' : 'hidden'} onClick={() => this.cerrarModal()}>
                                        {noticia.texto_boton_app}
                                    </button>
                                    {this.state.time.s}
                                </div>
                            )
                        })}
                        <ul className="list-unstyled portada">
                            {noticias.map(noticia => {
                                return (
                                    <li className="noticias-list-li portada" key={noticia.id}>
                                        <div className="noticia-portada-index">
                                            <div className="noticias-titulo">
                                                {noticia.titulo}
                                            </div>
                                            <div className="noticias-descripcion">
                                                <span className="noticias-descripcion" dangerouslySetInnerHTML={{ __html: noticia.descripcion }} />
                                            </div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </ModalBody>
                </Modal>
            </div>
        )
    }


}

export default NoticiaModal;