import React from 'react';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";


import "../../styles/GlobalComponents.css";

class PageinfoTranslated extends React.Component {
    render() {
        const { t } = this.props;
        const info = this.props.datos;
        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span>{t(`${info}.titulo`,)}</span>
                </div>
                <div className="lista">
                    <span className="no-coment">{t(`${info}.info`,)}</span>
                </div>
            </div>
        )
    }
}
const Pageinfo = withTranslation('common')(PageinfoTranslated)
export default Pageinfo;