import React from 'react';
import "../../styles/GlobalPages.css";
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import { withTranslation } from "react-i18next";
import api from "../../components/servicios/api";
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import PiePagina from '../../components/Navbar/PiePagina';
import OfertasList from '../../components/Ofertas/OfertasList';


let userData;
class OfertasTranslated extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            error: null,
            datos: undefined,
            sinDatos: false,
        }

    };

    componentDidMount() {
        this.fetchData();
    }


    click_factura = () => {
        // window.location = '/NuevaOferta';
    }


    fetchData = async () => {
        this.setState({ loading: true, error: null, datos: undefined });
        userData = '';
        try {
            await api.Clientes.ofertas().then(
                ({ data }) => (userData = data)
            );
            if (Array.isArray(userData)) {
                this.setState({ loading: false, datos: userData, sinDatos: false });
            } else if (userData == "sin datos" || "No se han encontrado datos para el modelo <b>listarClientes</b>" == userData) {
                this.setState({ loading: false, sinDatos: true, datos: false });
            } else {
                this.setState({ loading: false, error: true });
            }
        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };


    render() {

        const { t } = this.props;
        if (this.state.loading) {
            return <PageLoading />;
        }

        if (this.state.error) {
            return <PageError />;
        }

        return (
            <div className="container h-100">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span>{/*t('Clientes.titulo',)*/} Ofertas</span>
                </div>
                <div className={this.state.sinDatos ? 'lista' : 'hidden'}>
                    <span className="no-coment">{t('Facturas.info',)}</span>
                </div>
                <div className={this.state.sinDatos ? 'hidden' : 'lista'}>
                    {this.state.datos && <OfertasList clientes={this.state.datos} />}
                </div>
                {this.state.loading && <MiniLoader />}
                <button className='btn-aceptar' onClick={() => this.click_factura()} >Añadir Oferta</button>
                <PiePagina />
            </div>
        );
    }
}
const Ofertas = withTranslation('common')(OfertasTranslated)
export default Ofertas;