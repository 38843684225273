import React from 'react';
import { IonFooter, IonToolbar, IonTitle } from '@ionic/react';

class PiePagina extends React.Component {
    render() {
        return (
            <IonFooter>
                <IonToolbar color="blue">
                    <IonTitle></IonTitle>
                </IonToolbar>
            </IonFooter>
        )
    }
}
export default PiePagina;