import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../styles/GlobalPages.css";
import { withTranslation } from "react-i18next";
import PiePagina from '../../components/Navbar/PiePagina';
import NavbarVariable from '../../components/Navbar/NavbarVariable';
import api from '../../components/servicios/api';
import PageLoading from "../../components/PantallaCarga/PageLoading";
import PageError from "../../components/PantallaCarga/PageError";
import MiniLoader from "../../components/PantallaCarga/MiniLoader";
import TarifaBloque from '../../components/Tarifas/TarifaBloque';
import Bonos from '../../components/Tarifas/Bonos';
let respuesta;
let tarifas = [];
let tarifasImagen;
let userConsumo;
class ContratacionTranslated extends React.Component {

    state = {
        loading: true,
        error: false,
        titulo: '',
        imagen: false,
        alert: false,
        bonos: '',
        operador: this.props.location.state.operador,
        form: {
            telefono: 'Seleccione un número'
        },
        datos: [],
        verDDi: false,
        ddi: this.props.location.state.ddi
    };



    componentDidMount() {
        if (this.state.operador == 0) {
            this.fetchTel();
            this.setState({ verDDi: true })
        } else {
            this.fetchData();
        }

    }

    handleChange = async (e) => {
        await this.setState({
            form: {
                ...this.state.form,
                [e.target.name]: e.target.value,
            },
            ddi: e.target.value
        });

        console.log(this.state.form.telefono)
        this.fetchData(this.state.form.telefono);

    };

    fetchTel = async () => {
        this.setState({ loading: true, error: null });
        userConsumo = undefined;

        try {
            const cliente = JSON.parse(localStorage.getItem('user'));
            await api.Clientes.telefonos(cliente.usuario).then(
                ({ data }) => (userConsumo = data)
            ).catch(function (err) {
                console.log(err);
            });


            if (!Array.isArray(userConsumo)) {
                this.abrirAlert();
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loading: false, datos: userConsumo });
            }
        } catch (error) {
            this.setState({ loading: false, error: error });
        }
    };

    fetchData = async (tel) => {
        this.setState({ loading: true, error: null });
        tarifas = [];
        try {
            //Recuperamos tarifas moviles
            await api.Clientes.listarBonos(this.state.operador, tel).then(
                ({ data }) => (respuesta = data)
            );


            if (Array.isArray(respuesta)) {
                for (let i = 0; i < respuesta.length; i++) {
                    if (respuesta[i].nombre_comercial_app) {
                        respuesta[i].nombre_comercial_app = respuesta[i].nombre_comercial_app.substr(0, 25);
                    }
                    if (respuesta[i].total_iva) {
                        respuesta[i].total_iva = parseFloat(respuesta[i].total_iva).toFixed(2);
                    }
                    tarifas.push(respuesta[i]);
                }

                if (this.state.imagen == 1) {
                    for (let i = 0; i < tarifas.length; i++) {
                        if (tarifas[i].imagen_tarifa != '' && tarifas[i].imagen_tarifa != null) {
                            tarifasImagen.push(tarifas[i]);
                        }

                    }
                }

                this.setState({ loading: false, error: true, bonos: tarifas });
            } else if (respuesta == "sin datos" || respuesta == 'No se han encontrado datos para el modelo <b>tarifas_app</b>' || respuesta == 'No se han encontrado datos para el modelo <b>tarifas_compuestas</b>') {
                tarifas = [];
                this.setState({ loading: false, error: true });
            } else {
                this.setState({ loading: false, error: true });
            }

        } catch (error) {
            this.setState({ loading: false, error: true });
        }
    };


    render() {
        const { t } = this.props;
        if (this.state.loading === true && !this.state.datos) {
            return <PageLoading />;
        }

        return (

            <div className="container h-100 ">
                <div className="nav-navegacion">
                    <NavbarVariable datos={'/Home'} />
                </div>
                <div className="titulo-pagina">
                    <span>{t('Contratacion.titulo',)}</span>
                </div>
                {this.state.verDDi && <div className='ddi-bono-seleccionar'><div className='telefono-bono'>
                    <span>Seleccione el número de teléfono</span>
                </div>
                    <div className="">
                        <form onSubmit={this.props.onSubmit}>
                            <div className="select-telefonos-air">
                                <select name="telefono" onChange={this.handleChange} className="select-tlf-air" value={this.state.form.telefono}>
                                    <option key="0">{t('Consumo.numero',)}</option>
                                    {this.state.datos.map((dato) =>
                                        <option key={dato} value={dato}>{dato}</option>
                                    )}
                                </select>
                            </div>
                        </form>
                        {this.state.sinDatos == true ? <div className='sin-datos'><span >{t('Consumo.sinDatos',)}</span></div> : ''}
                    </div></div>}
                <div className='bloque-bonos'>
                    {this.state.bonos &&
                        <div className="tarifas">
                            {tarifas.map(tarifa => {
                                return (
                                    <ul key={tarifa.id}>
                                        <Bonos
                                            id={tarifa.id}
                                            nombre={tarifa.nombre_comercial_app}
                                            descripcion={tarifa.descripcion_web}
                                            precio={tarifa.total_iva}
                                            ampliacion={tarifa.descripcion_web_ampliacion}
                                            color={process.env.REACT_APP_TARIFAS}
                                            tipo_bono_api={tarifa.tipo_bono_api}
                                            ddi={this.state.ddi} />
                                    </ul>
                                );
                            })}
                        </div>}

                </div>
                {this.state.loading && <MiniLoader />}
                <PiePagina />
            </div>
        )
    }
}
const Contratacion = withTranslation('common')(ContratacionTranslated)
export default Contratacion;